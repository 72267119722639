import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { CancelConfirmComponent } from 'src/app/common-ui/cancel-confirm/cancel-confirm.component';
import { ProspectConsentService } from 'src/app/services/api/prospect-consent.service';
import { RedirectService } from 'src/app/services/common/redirect.service';
import { ModalService } from 'src/app/services/modal.service';
import { PrivacyDisclosureComponent } from '../privacy-disclosure/privacy-disclosure.component';

@Component({
  selector: 'app-prospect-consent-container',
  templateUrl: './prospect-consent-container.component.html',
  styleUrls: ['./prospect-consent-container.component.scss']
})
export class ProspectConsentContainerComponent implements OnInit {

  private id: string;
  private languageCd: string;
  private disclosureDocument: string;
  @ViewChild('btn', { static: true }) button: ElementRef;

  constructor(private prospectConsentService: ProspectConsentService, private dialog: MatDialog, private routeParams: ActivatedRoute, private router: Router, private modalService: ModalService, private translate: TranslatePipe, private redirectService: RedirectService) {
  }

  ngOnInit(): void {
    this.routeParams.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.languageCd = params.lang;
      }
      );
    this.prospectConsentService.getEmailDisclosureDocument(this.id, this.languageCd).subscribe(
      responseData => {
        this.disclosureDocument = responseData.body.disclosureDocument;
      },
      error => {
        if (error.error.status.additionalStatus[0].serverStatusCode == 'DIG.802') {
          this.router.navigateByUrl('/max-limit');
        } else {
          this.router.navigateByUrl('/agent-error');
        }
      });
  }

  openDisclosureDialog() {
    var optionsData = {
      body: [{ content: window.atob((this.disclosureDocument[0] as any).base64EncodedData), center: false }],
      buttons: [{ label: this.translate.transform("customer-flow.privacy-policy-modal.printBtn-label"), id: 'printBtn', className: 'td-button-secondary' },
      { label: this.translate.transform("customer-flow.privacy-policy-modal.saveBtn-label"), id: 'saveBtn', className: 'td-button-secondary' }]
    };

    var options = { data: optionsData, disableClose: true, autoFocus: true, panelClass: 'custom-dialog-container' }
    this.modalService.openModal(PrivacyDisclosureComponent, options);
  }

  openVerifiedMe() {
    this.prospectConsentService.retrieveVMRedirectURL(this.id).subscribe(
      responseData => {
        this.redirectService.redirect(responseData, '_self');
      });;
  }

  openCancelConfirmation() {
    var optionsData = {
      title: this.translate.transform("customer-flow.cancel-confirmation-modal.primary-header"),
      body: [{ content: this.translate.transform("customer-flow.cancel-confirmation-modal.information-body-copy") }],
      buttons: [{ label: this.translate.transform("customer-flow.cancel-confirmation-modal.resumeBtn-label"), id: 'resumeBtn', className: 'td-button cancel-button td-button-clear-green' },
      { label: this.translate.transform("customer-flow.cancel-confirmation-modal.cancelBtn-label"), id: 'cancelBtn', className: 'td-button td-button-block td-button-secondary continue-button' }]
    };

    var options = { data: optionsData, disableClose: true, autoFocus: true, role: "dialog", ariaDescribedBy: "mat-dialog-info-0", ariaLabelledBy: "mat-dialog-title-0", id:"mat-dialog-0" }
    this.modalService.openModal(CancelConfirmComponent, options);
  }
}


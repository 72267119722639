import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserIdleConfig } from 'angular-user-idle';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TimeoutConfig } from 'src/app/models/common/timeout-config.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configuration: UserIdleConfig;

  private timeoutConfigDataPath: string = "/dig-bff/timeoutconfig";

  private defaultTimeout: number = 300;
  private defaultIdle: number = 1800;
  private defaultTimeoutConfig: TimeoutConfig;

  constructor(private http: HttpClient) {
    this.defaultTimeoutConfig = new TimeoutConfig();
    this.defaultTimeoutConfig.idle = this.defaultIdle;
    this.defaultTimeoutConfig.timeout = this.defaultTimeout;

  }

  loadConfig() {
    return this.http.get<TimeoutConfig>(this.timeoutConfigDataPath, { observe: 'body' })
      .pipe(
        tap(timeoutConfig => {
          this.configuration = new UserIdleConfig();
          this.configuration.idle = timeoutConfig.idle;
          this.configuration.timeout = timeoutConfig.timeout;
        }),
        catchError(
          (error: HttpErrorResponse): Observable<any> => {
            if (error.status === 404) {
              this.configuration = new UserIdleConfig();
              this.configuration.idle = this.defaultIdle;
              this.configuration.timeout = this.defaultTimeout;
              return of(this.defaultTimeoutConfig);
            }
            return throwError(error);
          },
        ),
      )
      .toPromise();
  }

  public getTimeoutInSeconds(): number {
    return this.configuration.timeout;
  }

}

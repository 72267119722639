<div class="input-group" [formGroup]="formGroupControl" [class.has-error]="isInvalid(control)">
    <div class="label-elements">
        <td-input-label 
            [formGroupControl]="formGroupControl"
            [controlName]="controlName"
            [labelText]="labelText"
            [baseKey]="baseKey"
            [hasTooltip]="hasTooltip"
            [tooltipText]="tooltipText"
            [highlight]="highlight"
            [labelLink]="labelLink"
        ></td-input-label>
        <div class="td-select">
            <select
                [formControlName]="controlName"
                [attr.name]="controlName+idSuffix"
                [id]="controlName+idSuffix"
                [attr.aria-describedby]="baseKey + '.' + controlName + '.inputError'"
                (change)="onChange($event)"
                class="ctHidden form-control"
                [class.highlight]="!!highlight"
                [class.empty]="isEmpty(control)">
                <optgroup style="display:none" label=""></optgroup>
                <option
                    [id]="controlName + 'NullValue'"
                    class="ctHidden"
                    [value]="''"
                    [innerHtml]="defaultOption | translate"
                ></option>
                <ng-content></ng-content>
                <ng-container *ngIf="options?.length>0">
                    <option class="ctHidden" 
                        *ngFor="let item of options" 
                        [value]="item.value"
                        [innerHTML]="item.name | translate"
                        [class.selected]="control.value===item.value"
                    ></option>
                </ng-container>
            </select>
        </div>
        <td-error-message [formGroup]="formGroupControl" [controlName]="controlName" [baseKey]="baseKey" [labelText]="labelText" [idSuffix]="idSuffix"></td-error-message>
    </div>
</div>
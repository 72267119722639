<div class="td-row" mat-dialog-action>
    <div class="td-col-xs-12 text-center flex-container">
        <ng-container *ngFor="let btn of buttons; let i = index">
            <div class="btn-container modal-action" [class.td-modal-btn-mobile]="btn.mobile"
                [class.td-modal-btn-desktop]="btn.desktop">
                <ng-container *ngIf="btn.type==='anchor'; else modalBtn">
                    <a href="javascript:void(0)" (click)="onButtonClick(btn)" type="button" role="button"
                        class="td-link-inline {{btn.className}}"
                        [attr.data-analytics-click]="btn.track? ((btn.analytics || (buttonBaseKey + btn.label + '.omniTag'))  | translate): null">{{ linkBaseKey + btn.label | translate}}</a>
                </ng-container>
                <ng-template #modalBtn>
                    <button type="button" [id]="btn.id" (click)="onButtonClick(btn)" mat-dialog-close
                        class="td-button td-button-block {{btn.className}}" [class.trackingbutton]="btn.track"
                        [attr.allowUnload]="btn.id === this.btnCancelAppId"
                        [attr.data-analytics-click]="btn.track? ((btn.analytics || (buttonBaseKey + btn.label + '.omniTag'))  | translate): null">
                        {{ buttonBaseKey + btn.label | translate }}
                    </button>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>

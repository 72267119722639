import * as moment_ from 'moment';
import 'moment-timezone';

const customMoment: any = moment_;

const timezone = 'America/New_York';
if (customMoment.tz) {
    customMoment.tz.setDefault(timezone);
}

export const getDate = (value, isDob = false) => {
    let date = null;
    if (isDob && customMoment.tz) {
        const temp = customMoment(value, 'MM/DD/YYYY');
        date = customMoment.tz([temp.years(), temp.month(), temp.date(), 3, 0], timezone);
    } else {
        date = customMoment.tz(value, timezone);
    }
    return date;
};

export { customMoment };

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'td-modal-close',
    templateUrl: './close.component.html',
    styleUrls: ['./close.component.scss']
})
export class ModalCloseComponent implements OnInit {
    @Output() close = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() { }

    dismiss($event) {
        $event.preventDefault();
        this.close.emit(true);
    }
}

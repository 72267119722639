import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailDisclosureModel } from 'src/app/models/customer-flow/api/response/email-disclosure-model';

@Injectable({
  providedIn: 'root'
})
export class ProspectConsentService {

  private emailDisclosureDataPath: string = "/dig-bff/prospectwebvisits/sessioninformation";
  private retrieveVMRedirectURLPath: string = "/dig-bff/prospectwebvisits/vmeredirecturl";

  constructor(private http: HttpClient) { }

  public getEmailDisclosureDocument(xSessionId: string, languageCd: string) {
    const headers = {
      'X-Session-ID': xSessionId,
      'Accept-Language': languageCd,
      'Content-Type': 'application/json'
    };
    return this.http.get<EmailDisclosureModel>(this.emailDisclosureDataPath, { headers: new HttpHeaders(headers), observe: 'response' });
  }

  public retrieveVMRedirectURL(xSessionId: string) {
    const headers = {
      'X-Session-ID': xSessionId
    };
    return this.http.get<string>(this.retrieveVMRedirectURLPath, { headers: new HttpHeaders(headers), responseType: 'text' as 'json' });
  }
}
import { Component, Inject, HostBinding, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const btnCancelAppId = 'cancelApp';
export interface DialogData {
    title: string;
    body: string;
    hasAction: boolean;
    continueText: string;
}
@Component({
    selector: 'td-modal-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
})

export class ModalBaseComponent implements OnInit {
    @Input() hasClose = false;
    @Input() hasTitle = true;
    @Input() name;
    @Input() classes = '';
    @Input() baseKey;
    @Input() title = 'title';
    @Input() body;
    @Input() buttons = [];
    @Output() btnClick = new EventEmitter();

    contents = [];

    constructor(public dialogRef: MatDialogRef<ModalBaseComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (this.data) {
            this.hasClose = this.data.hasClose || this.hasClose;
            this.baseKey = this.data.baseKey || this.baseKey;
            this.title = this.data.title || this.title;
            this.body = this.data.body || this.body;
            this.buttons = this.data.buttons || this.buttons;
            this.name = this.data.name || this.name;
            this.classes = this.data.classes || this.classes;
        }
        this.classes = this.classes || (this.name ? this.name + '-modal' : '');
        this.contents = this.body ? [].concat([], this.body) : [];
    }

    onButtonClick(btn): void {
        this.btnClick.emit(btn.id);

        if (btn.id !== btnCancelAppId) {
            this.dialogRef.close(btn.id);
        }
    }

    closeDialog() {
        this.onButtonClick('dismiss');
    }

    @HostBinding('class') get className() {
        return this.classes;
    }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ErrorAlertService } from '../error-alert/error-alert.service';
import { InputBaseComponent } from '../input-base/input-base.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'td-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent extends InputBaseComponent {
    @Input() tooltipText: string;
    @Input() hasTooltip = false;
    @Input() masking;
    @Input() patterns;
    @Input() inputFormatters = [];
    @Input() inputParsers = [];
    @Input() minLength;
    @Input() maxLength;
    @Input() placeholder = '';
    @Input() type = 'text';

    constructor(errorAlertService: ErrorAlertService) { super(errorAlertService); }
}

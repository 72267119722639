<div class="td-row">
  <div
    class="
      td-col-xs-12
      td-col-sm-10
      td-col-sm-offset-1
      td-col-md-8
      td-col-md-offset-2
    "
  >
    <h1 class="text-center" tabindex="-1">{{ "agent-flow.prospect-waiting-container.primary-header"| translate }}</h1>
    <h2 class="text-center">{{ "agent-flow.prospect-waiting-container.personal-info-header"| translate }}</h2>

    <form #postForm="ngForm" (ngSubmit)="onRefresh()">
      <div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="givenName">
                  {{"agent-flow.prospect-waiting-container.firstName-label"| translate}}</label>
                <output id="givenName" [textContent]="prospectInput?.givenName"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="middleName">{{"agent-flow.prospect-waiting-container.middleName-label"| translate}}</label>
                <output id="middleName" [textContent]="prospectInput?.middleName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="lastName">{{"agent-flow.prospect-waiting-container.lastName-label"| translate}}</label>
                  <output id="lastName" [textContent]="prospectInput?.familyName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="DateofBirth">{{"agent-flow.prospect-waiting-container.dateOfBirth-label"| translate}}</label>
                <output id="DateofBirth">{{prospectInput?.birthDt | date: 'MM/dd/yyyy'}}</output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="mobilePhone">{{"agent-flow.prospect-waiting-container.mobilePhoneNumber-label"| translate}}</label>
                <output id="mobilePhone" [textContent]="prospectInput?.telephoneNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="SecondaryPhone">{{"agent-flow.prospect-waiting-container.secondaryPhoneNumber-label"| translate}}</label>
                <output id="SecondaryPhone" [textContent]="prospectInput?.secondaryTelephoneNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="emailAddress">{{"agent-flow.prospect-waiting-container.emailAddress-label"| translate}}</label>
                <output id="emailAddress" [textContent]="prospectInput?.emailAddress"></output>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-center">Address</h2>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="streetNum">{{"agent-flow.prospect-waiting-container.streetNumber-label"| translate}}</label>
                <output id="streetNum" [textContent]="prospectInput?.streetNum"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="unitNum">{{"agent-flow.prospect-waiting-container.unitNumber-label"| translate}}</label>
                <output id="unitNum" [textContent]="prospectInput?.unitNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="streetName">{{"agent-flow.prospect-waiting-container.streetName-label"| translate}}</label>
                <output id="streetName" [textContent]="prospectInput?.streetName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="unitTypeCd">{{"agent-flow.prospect-waiting-container.unitType-label"| translate}}</label>
                  <output id="unitTypeCd" [textContent]="prospectInput?.unitTypeCd"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="city">{{"agent-flow.prospect-waiting-container.city-label"| translate}}</label>
                <output id="city" [textContent]="prospectInput?.cityName"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="territoryCd">{{"agent-flow.prospect-waiting-container.province-label"| translate}}</label>
                <output id="territoryCd" [textContent]="prospectInput?.territoryCd"></output>
              </div>
            </div>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="postalCodeNum">{{"agent-flow.prospect-waiting-container.postalCode-label"| translate}}</label>
                <output id="postalCodeNum" [textContent]="prospectInput?.postalCodeNum"></output>
              </div>
            </div>
          </div>
        </div>

        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="country">{{"agent-flow.prospect-waiting-container.country-label"| translate}}</label>
                <output id="country">Canada</output>
              </div>
            </div>
          </div>
        </div>

        <section>
        <h2 class="text-center">
          {{
            "agent-flow.prospect-complete-container.interacService-primary-header"
              | translate
          }}
        </h2>

            <div>
              <div class="waiting-results-panel">
                <p class="text-center">
                  <b>{{
                    "agent-flow.prospect-waiting-container.confirmation-body-copy"
                      | translate
                  }}</b>
                </p>
              </div>
            </div>
        </section>


        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-offset-4 td-col-sm-4">
            <div class="btn-container">
              <button
                class="td-button td-button-block td-button-secondary
                  continue-button"
                id="continueBtn"
                type="submit"
                [disabled]="!prospectComplete"
                (click)="onRefresh()">{{"agent-flow.prospect-waiting-container.refreshBtn-label"| translate}}
              </button>
            </div>
            <div class="">
              <p class="text-center">
                {{
                  "agent-flow.prospect-waiting-container.trademark-copy"
                  | translate
                  }}
              </p>
            </div>
            <div class="btn-container">
              <a class="td-link-inline cancel-button" href="javascript:void(0)" id="btnCancel"
              (click)="openCancelModal()" allowunload="true">{{
              "agent-flow.prospect-waiting-container.cancelBtn-label" | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatTimePipe, TimeoutDialogComponent } from './timeout-dialog.component';
import { MatDialogModule} from "@angular/material/dialog";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TimeoutDialogComponent, FormatTimePipe],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule
  ],
  exports: [TimeoutDialogComponent]
})
export class TimeoutDialogModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelWithoutCifDialogComponent } from './cancel-without-cif-dialog.component';
import { MatDialogModule} from "@angular/material/dialog";
import { TranslateModule } from '@ngx-translate/core';
import { ModalConfigModule } from '../modal/modal.module';


@NgModule({
  declarations: [CancelWithoutCifDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    ModalConfigModule
  ],
  exports: [CancelWithoutCifDialogComponent]
})
export class CancelWithoutCifDialogModule { }

<div class="row">
    <div class="
          td-col-xs-12
          td-col-sm-10
          td-col-sm-offset-1
          td-col-md-8
          td-col-md-offset-2
        ">
      <h1 class="text-center ng-star-inserted">
        {{ "agent-flow.access-denied.primary-header" | translate }}
      </h1>
    </div>
  </div>
  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-10 td-col-sm-offset-1 td-col-md-8 td-col-md-offset-2">
      <p class="text-center">
        {{ "agent-flow.access-denied.information-body-copy" | translate }}
      </p>

    </div>
  </div>

  <div class="td-row ng-star-inserted">
    <div class="td-col-xs-12 td-col-sm-offset-4 td-col-sm-4">
      <div class="btn-container">
        <button class="
              td-button td-button-block td-button-secondary continue-button" id="continueBtn" type="button"
          (click)="cancelFlow()">
          {{ "agent-flow.access-denied.okBtn-label" | translate }}
        </button>
      </div>
    </div>
  </div>

import { Injectable, InjectionToken } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { NgZone } from '@angular/core';
import { ModalBaseComponent } from '../common-ui/modal/base/base.component';

export const MODAL_CONFIG = new InjectionToken<any>('MODAL_CONFIG');

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modalRef: MatDialogRef<any>;
  modalTypes = {};

  constructor(private matDialog: MatDialog, private zone: NgZone) { }

  registerModal(type, modal) {
    this.modalTypes[type] = modal;
  }

  getModalWidth(size) {
    let width = 0;
    switch (size) {
      case 'lg':
        width = 969;
        break;
      case 'md':
        width = 770;
        break;
      default:
        width = 770;
    }
    return width;
  }

  getDefaultOptions(options) {
    return {
      width: this.getModalWidth(options.size) + 'px',
      autoFocus: false,
      closeOnNavigation: true,
      restoreFocus: true,
      hasBackdrop: true,
      data: options.data || {},
      role: 'dialog',
    };
  }

  openModal(modal, options: any = { size: 'md' }) {
    this.closeAll();

    const config = typeof (modal) === 'string' && this.modalTypes[modal] || { component: modal };
    modal = config.component || ModalBaseComponent;
    options = { ...options, ...config.options };

    const triggerElement: any = document.activeElement;

    this.zone.run(() => {
      this.modalRef = this.matDialog.open(modal, { ...this.getDefaultOptions(options), ...options });
    });

    this.modalRef.afterOpened().subscribe(result => {
      const container = document.querySelector(`#${this.modalRef.id}`);
      const closeBtn = container.querySelector('.modal-close');
      const headings = container.querySelectorAll('h1,h2,h3,h4,h5,h6');
      const focusElement: any = closeBtn || headings[0] || container;
      focusElement.focus();
    });
    return this.modalRef.afterClosed().pipe(map(result => {
      triggerElement.focus();
      return result;
    }));
  }

  close() {
    this.modalRef.close('closed');
  }

  closeAll() {
    if (this.modalRef) { this.modalRef.close(); }
    this.matDialog.closeAll();
    this.matDialog.afterAllClosed.subscribe();
  }

}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { btnCancelAppId } from '../base/base.component';
@Component({
    selector: 'td-modal-buttons',
    templateUrl: './buttons.component.html',
    styleUrls: ['./buttons.component.scss']
})
export class ModalButtonsComponent implements OnInit {
    @Input() baseKey;
    btnCancelAppId = btnCancelAppId;
    get linkBaseKey() {
        return this.baseKey ? this.baseKey + '.' : '';
    }
    get buttonBaseKey() {
        return this.baseKey ? this.baseKey + '.button.' : '';
    }
    @Input() buttons;
    @Output() btnClick = new EventEmitter();

    @Input() modalTitle = '';

    constructor() { }

    ngOnInit() { }

    onButtonClick(btn) {
        this.btnClick.emit(btn);
    }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProspectCompleteContainerComponent } from './agent-flow/prospect-complete-container/prospect-complete-container.component';
import { ProspectInputContainerComponent } from './agent-flow/prospect-input-container/prospect-input-container.component';
import { ProspectWaitingContainerComponent } from './agent-flow/prospect-waiting-container/prospect-waiting-container.component';
import { SystemErrorComponent } from './agent-flow/system-error/system-error.component';
import { MaxLimitComponent } from './common-ui/max-limit/max-limit.component';
import { SessionExpiredComponent } from './common-ui/session-expired/session-expired.component';
import { ProspectConsentContainerComponent } from './customer-flow/prospect-consent-container/prospect-consent-container.component';
import { ProspectSuccessContainerComponent } from './agent-flow/prospect-success-container/prospect-success-container.component';
import { AccessDeniedComponent } from './common-ui/access-denied/access-denied.component';
import { ProspectSystemErrorComponent } from './customer-flow/prospect-system-error/prospect-system-error.component';
import { ProspectConfirmComponent } from './customer-flow/prospect-confirm/prospect-confirm.component';
import { ProspectSessionExpiredComponent } from './customer-flow/prospect-session-expired/prospect-session-expired.component';

const routes: Routes = [
  { path: 'prospect-input', component: ProspectInputContainerComponent },
  { path: 'waiting', component: ProspectWaitingContainerComponent },
  { path: 'complete', component: ProspectCompleteContainerComponent },
  { path: 'session-expired', component: SessionExpiredComponent},
  { path: 'max-limit', component: MaxLimitComponent},
  { path: 'prospect-confirm', component: ProspectConfirmComponent},
  { path: 'agent-error', component: SystemErrorComponent },
  { path: 'prospect-error', component: ProspectSystemErrorComponent },
  { path: 'prospect-consent', component: ProspectConsentContainerComponent },
  { path: 'success', component: ProspectSuccessContainerComponent },
  { path: 'access-denied', component: AccessDeniedComponent},
  { path: 'prospect-session-expired', component: ProspectSessionExpiredComponent},
  { path: '',  pathMatch: 'full', redirectTo: 'agent-error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<td-modal-close (close)="closeDialog()" *ngIf="hasClose"></td-modal-close>
<td-modal-title *ngIf="hasTitle" [baseKey]="baseKey" [title]="title"></td-modal-title>
<div class="td-row" mat-dialog-content>
    <div class="td-col-xs-12">
        <ng-container *ngFor="let c of contents">
            <p [class.text-center]="c.center!==false" [innerHTML]="((c.content || c) | translate: (c.params || {}))"></p>
        </ng-container>
        <ng-content></ng-content>
    </div>
</div>
<td-modal-buttons *ngIf="buttons" [baseKey]="baseKey" [modalTitle]="title" [buttons]="buttons" (btnClick)="onButtonClick($event)"></td-modal-buttons>
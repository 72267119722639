import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import {
  DatepickerHeaderComponent,
  TD_DATEPICKER_HEADER_LABELS,
  defaultLabels,
} from './datepicker-header.component';
import { DatepickerComponent } from './datepicker.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false,
      clearIfNotMatch: false,
    }),

    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    MatNativeDateModule,
    TranslateModule,
  ],
  declarations: [DatepickerComponent, DatepickerHeaderComponent],
  entryComponents: [DatepickerHeaderComponent],
  exports: [
    DatepickerHeaderComponent,
    DatepickerComponent,
    MatNativeDateModule,
    MatMomentDateModule,
  ],
  providers: [
    { provide: TD_DATEPICKER_HEADER_LABELS, useValue: defaultLabels },
  ],
})
export class DatepickerModule {
  static forRoot(config): ModuleWithProviders<DatepickerModule> {
    return {
      ngModule: DatepickerModule,
      providers: [
        { provide: TD_DATEPICKER_HEADER_LABELS, useValue: config.labels },
      ],
    };
  }
}

import { ElementRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserIdleService } from 'angular-user-idle';
import { fromEvent } from 'rxjs';
import { merge } from 'rxjs';
import { first } from 'rxjs/operators';
import { TimeoutDialogComponent } from '../common-ui/timeout-dialog/timeout-dialog.component';
import { ProspectInputService } from './api/prospect-input-service.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {

  static resumeCount: number = 0;
  dialogRef: MatDialogRef<TimeoutDialogComponent>;

  constructor(private userIdleService: UserIdleService, private dialog: MatDialog, private prospectInputService: ProspectInputService, private router: Router) { }

  registerEvent(btn: ElementRef) {
    this.userIdleService.setCustomActivityEvents(merge(fromEvent(document, 'load'),
      fromEvent(btn.nativeElement, 'click')));
  }

  start() {
    this.userIdleService.startWatching();
    this.userIdleService.onTimerStart().subscribe(count => {
      console.log("Timer count: " + count);
    });
    this.userIdleService.onIdleStatusChanged().subscribe(isIdleStatusChanged => {
      console.log("Idle status changed?: " + isIdleStatusChanged);
    })
    this.userIdleService.onTimeout().pipe(first()).subscribe(() => {
      this.openDialog();
      TimeoutService.resumeCount++;
    });
  }

 createDialogRef(): MatDialogRef<TimeoutDialogComponent>{
    if (this.dialogRef == null || TimeoutService.resumeCount <= 1){
        const dialogConfig = new MatDialogConfig()
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.dialogRef = this.dialog.open(TimeoutDialogComponent, dialogConfig);
    }
    return this.dialogRef;
 }


  openDialog() {
    if(TimeoutService.resumeCount < 2){
      this.createDialogRef().afterClosed().subscribe(
        data => {
          if (data == "RESUME") {
            this.userIdleService.stopWatching();
            this.prospectInputService.pollForIdentityProofingResult();
            this.userIdleService.startWatching();
            this.userIdleService.onTimeout().pipe(first()).subscribe(() => {
              this.openDialog();
              TimeoutService.resumeCount++;
            });
          } else if (data == "EXIT_WITHOUT_CLOSING") {
            this.userIdleService.stopWatching();
            this.prospectInputService.invalidateSessionCache().subscribe(
              () => {
                this.cleanSessionAndCloseWindow();
              });
          } else if (data == "EXIT") {
            this.userIdleService.stopWatching();
            this.prospectInputService.invalidateSessionCache().subscribe(
              () => {
                this.cleanSessionAndCloseWindow();
                window.close();
              });
          }
        }
      );
    }
    else if (TimeoutService.resumeCount >= 2) {
      this.userIdleService.stopWatching();
      this.prospectInputService.invalidateSessionCache().subscribe(
        () => {
          this.cleanSessionAndCloseWindow();
        });
      this.createDialogRef().close();
      this.router.navigateByUrl('/session-expired');
    }
  }

  cleanSessionAndCloseWindow() {
    localStorage.clear();
    sessionStorage.clear();
  }

  stop() {
    this.userIdleService.stopWatching();
  }

  reset() {
    this.userIdleService.stopWatching();
    //this.prospectInputService.pollForIdentityProofingResult()
    this.userIdleService.startWatching();
    this.userIdleService.onTimeout().pipe(first()).subscribe(() => this.openDialog());
  }

}

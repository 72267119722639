import { Component, OnInit } from '@angular/core';
import { AgentSessionService } from 'src/app/services/agent-session-service.service';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
import { ProspectPageInputModel } from 'src/app/models/agent-flow/ui/prospect-page-input.model';
import { ErrorAlertService } from 'src/app/common-ui/error-alert/error-alert.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CancelWithoutCifDialogComponent } from 'src/app/common-ui/cancel-without-cif-dialog/cancel-without-cif-dialog.component';
import { Router } from '@angular/router';
import { AssetBundleModel } from 'src/app/models/agent-flow/api/common/asset-bundle.model';
import { ModalService } from 'src/app/services/modal.service';
import { TranslatePipe } from '@ngx-translate/core';
import { CancelModalComponent } from 'src/app/common-ui/cancel-modal/cancel-modal.component';

@Component({
  selector: 'app-prospect-complete-container',
  templateUrl: './prospect-complete-container.component.html',
  styleUrls: ['./prospect-complete-container.component.scss']
})
export class ProspectCompleteContainerComponent implements OnInit {

  prospectInput: ProspectPageInputModel = {};
  creditBureauBundleData : AssetBundleModel = {};
  otherFinancialInstitutionBundleData : AssetBundleModel = {};

  successfulMatch: boolean = true;
  singleSourceCB: boolean = false;
  singleSourceOFI: boolean = false;

  constructor(private agentSessionService: AgentSessionService, private prospectInputService: ProspectInputService,  private errorAlertService: ErrorAlertService, private dialog: MatDialog, private router: Router, private modalService: ModalService, private translate: TranslatePipe,) { }

  openCancelModal(){
    var optionsData = {
      title: this.translate.transform("agent-flow.cancel-without-cif-code-modal.primary-header"),
      body: [{ content: this.translate.transform("agent-flow.cancel-without-cif-code-modal.information-body-copy") }],
      buttons: [{ label: this.translate.transform("agent-flow.cancel-without-cif-code-modal.resumeBtn-label"), id: 'resumeBtn', className: 'td-button cancel-button td-button-clear-green' },
      { label: this.translate.transform("agent-flow.cancel-without-cif-code-modal.cancelBtn-label"), id: 'cancelBtn', className: 'td-button td-button-block td-button-secondary continue-button' }]
    };

    var options = { data: optionsData, disableClose: true, autoFocus: true }
    this.modalService.openModal(CancelWithoutCifDialogComponent, options);
  }

  ngOnInit(): void {
    if(this.agentSessionService.matchingResultCd == undefined || this.agentSessionService.matchingResultCd == null || this.agentSessionService.prospectInput == undefined || this.agentSessionService.prospectInput == null){
      this.router.navigateByUrl('/agent-error');
    }

    if(this.agentSessionService.matchingResultCd == "MATCHING_FAILED"){
      this.successfulMatch = false;
    }

    if(this.agentSessionService.matchingResultCd == "MATCHING_PASSED"){
      this.successfulMatch = true;

      if(this.agentSessionService.creditBureauBundleData != null){
        this.singleSourceCB = true;
      }

      if(this.agentSessionService.otherFinancialInstitutionBundleData != null){
        this.singleSourceOFI = true;
      }
    }
    this.prospectInput = this.agentSessionService.prospectInput;
  }

  ngOnDestroy() {
    //this.timeInterval.unsubscribe();
 }

  onRefresh(): void {
  }

}




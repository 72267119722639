import { Component, OnInit } from '@angular/core';
import { AssetBundleModel } from 'src/app/models/agent-flow/api/common/asset-bundle.model';
import { AgentSessionService } from 'src/app/services/agent-session-service.service';

@Component({
  selector: 'app-fi-information',
  templateUrl: './fi-information.component.html',
  styleUrls: ['./fi-information.component.scss']
})
export class FiInformationComponent implements OnInit {

  otherFinancialInstitutionBundleData : AssetBundleModel = {};

  constructor(private agentSessionService: AgentSessionService) {
    this.otherFinancialInstitutionBundleData = this.agentSessionService.otherFinancialInstitutionBundleData;
   }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorAlertService } from '../error-alert/error-alert.service';

@Component({
    selector: 'td-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() controlName;
    @Input() baseKey;
    @Input() labelText;
    @Input() idSuffix = '';
    role = 'alert';

    get control() {
        return this.formGroup.controls[this.controlName];
    }

    constructor(private errorAlertService: ErrorAlertService) { }
    ngOnInit() {
        this.errorAlertService.submitted.subscribe(() => this.role = null);
        this.control.valueChanges.subscribe(() => this.role = 'alert');
        this.control.statusChanges.subscribe(() => this.role = 'alert');
    }

    get error() {
        let error;
        if (this.control && (this.control.errors && (this.control.dirty || this.control.touched))) {
            error = this.errorAlertService.getError(this.baseKey + '.' + this.controlName + this.idSuffix, 'inline').error;
        }
        return error;
    }
}

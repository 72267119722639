import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProspectConsentContainerComponent } from './prospect-consent-container/prospect-consent-container.component';
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PrivacyDisclosureComponent } from './privacy-disclosure/privacy-disclosure.component';
import { ModalConfigModule } from '../common-ui/modal/modal.module';
import { ProspectSystemErrorComponent } from './prospect-system-error/prospect-system-error.component';
import { ProspectConfirmComponent } from './prospect-confirm/prospect-confirm.component';
import { ProspectSessionExpiredComponent } from './prospect-session-expired/prospect-session-expired.component';

@NgModule({
  declarations: [ProspectConsentContainerComponent, PrivacyDisclosureComponent, ProspectSystemErrorComponent, ProspectConfirmComponent, ProspectSessionExpiredComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ModalConfigModule,
    RouterModule,
  ],
  providers: [TranslatePipe]
})
export class CustomerFlowModule { }

import { Component, OnInit } from '@angular/core';
import { AgentSessionService } from 'src/app/services/agent-session-service.service';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';

@Component({
  selector: 'app-prospect-success-container',
  templateUrl: './prospect-success-container.component.html',
  styleUrls: ['./prospect-success-container.component.scss']
})
export class ProspectSuccessContainerComponent implements OnInit {

  constructor(private agentSessionService: AgentSessionService, private prospectInputService: ProspectInputService) { }

  ngOnInit(): void {
    if(this.agentSessionService.matchingResultCd == "ON_BOARDING_COMPLETE"){
      this.prospectInputService.invalidateSessionCache().subscribe(
        () => {
          this.cleanSession();
        },
        () => {
          console.log('Cache failed to clear');
        });
    }
  }

  close() {
    window.close();
  }

  cleanSession() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

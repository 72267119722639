<td-modal-title id="mat-dialog-title-0" *ngIf="hasTitle" [baseKey]="baseKey" [title]="title"></td-modal-title>
<div class="row" mat-dialog-content>
    <div tabindex="0">
        <ng-container *ngFor="let c of contents">
            <p id="mat-dialog-info-0" [class.text-center]="c.center!==false" [innerHTML]="((c.content || c) | translate: (c.params || {}))">
            </p>
        </ng-container>
        <ng-content></ng-content>
    </div>
</div>
<td-modal-buttons *ngIf="buttons" [baseKey]="baseKey" [modalTitle]="title" [buttons]="buttons"
    (btnClick)="onButtonClick($event)"></td-modal-buttons>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-success-alert',
  templateUrl: './validation-success-alert.component.html',
  styleUrls: ['./validation-success-alert.component.scss']
})
export class ValidationSuccessAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

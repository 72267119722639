import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ErrorAlertService } from './error-alert.service';
declare const _: any;

@Component({
    selector: 'td-error-alert',
    templateUrl: './error-alert.component.html',
    styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent implements OnInit {
    @Input() type = 'danger';
    @Input() title = 'common-ui.error-alert.title';
    @Input() description = 'common-ui.error-alert.description';
    @Input() showErrorFields = true;
    @Input() fullWidth = true;
    @Input() customerNumberError = false;
    role;

    @ViewChild('wrapper') wrapper: ElementRef;

    private _showAlert;
    @Input()
    get showAlert() {
        return this._showAlert === undefined ? (this.submitted && this.errors.length > 0) : this._showAlert;
    }
    set showAlert(showAlert) {
        this._showAlert = showAlert;
    }

    get submitted() {
        return this.errorAlertService.submitted.getValue() > 0;
    }

    get errors() {
        const errors = this.errorAlertService.errors.getValue();
        return _.map(_.filter(_.keys(errors), key => {
            return errors[key].global;
        }), key => {
            return errors[key].labelText || key;
        });
    }

    get className() {
        switch (this.type) {
            case 'danger':
                return 'td-icon-close icon-danger';
            case 'warning':
                return 'td-icon-error icon-warning';
        }
    }

    get customerNumberFlag(){
        switch(this.customerNumberError){
            case true:
                return 'customer-number td-notification text-center';
            case false:
                return 'td-notification';
        }
	}

    constructor(public errorAlertService: ErrorAlertService, private focusMonitor: FocusMonitor) { }

    ngOnInit() {
        this.role = this.type === 'danger' ? 'alert' : null;
        this.errorAlertService.submitted.subscribe(submitted => {
            setTimeout(() => {
                const wrapper = this.wrapper?.nativeElement;
                if (wrapper) {
                    wrapper.removeAttribute('role');
                    wrapper.setAttribute('role', this.role);
                    this.focusMonitor.focusVia(wrapper, 'program');
                    if(this.customerNumberError == true)
                    {
                        window.scrollTo({ top: 300, left: 500, behavior: 'smooth' });
                    } else {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                }
            }, 100);
        });
    }
}

<app-header></app-header>

<div class="td-contentarea" id="main" style="padding-top: 106px" tabindex="-1">
  <div class="td-fullwidth">
    <div class="td-container">
      <div class="td-row">
        <div class="td-col-sm-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
    selector: 'td-modal-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss']
})
export class ModalTitleComponent implements OnInit {
    @Input() baseKey;
    @Input() title;

    constructor() { }

    ngOnInit() { }

}

<div class="td-row error-banner" role="alert">
  <p class="text-center">
    <span aria-hidden="true" class="td-icon td-icon-close icon-danger"></span>
    {{
      "agent-flow.prospect-complete-container.validation-unsuccessful-header"
        | translate
    }}
  </p>
</div>

<div class="td-row dotted-divider"></div>

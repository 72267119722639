import { Component, OnInit } from '@angular/core';
import { AgentSessionService } from 'src/app/services/agent-session-service.service';
import { AssetBundleModel } from 'src/app/models/agent-flow/api/common/asset-bundle.model';

@Component({
  selector: 'app-credit-bureau-information',
  templateUrl: './credit-bureau-information.component.html',
  styleUrls: ['./credit-bureau-information.component.scss']
})
export class CreditBureauInformationComponent implements OnInit {

  creditBureauBundleData : AssetBundleModel = {};

  constructor(private agentSessionService: AgentSessionService) { 
    this.creditBureauBundleData = this.agentSessionService.creditBureauBundleData;
  }

  ngOnInit(): void {
  }

}

<div class="td-row" [id]="baseKey + '.' + controlName + '.inputError'">
    <div class="td-col-xs-12">
        <div class="td-error">
            <div class="error-label" *ngIf="error" [attr.name]="error" [attr.role]="role">
                <span aria-hidden="true">{{ 'common-ui.label.error' | translate }}</span>
                <span class="sr-only" aria-atomic="true" aria-live="assertive">{{ 'common-ui.label.error.accessibilityTag' | translate }}</span>
                {{ error | translate }}
            </div>
        </div>
    </div>
</div>
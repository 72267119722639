import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

export declare type PLACEMENT_TYPES =
    | 'auto'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'left-top'
    | 'left-bottom'
    | 'right-top'
    | 'right-bottom';

@Component({
    selector: 'td-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit, OnDestroy {
    @Input() labelText;
    @Input() tooltipText;
    @Input() classes = '';
    @Input() isBlock = true;
    @Input() linkLeftAligned = true;
    @Input() placement: PLACEMENT_TYPES = 'bottom';
    @Input() hasTooltip = false;


    _width = 200;
    _tooltip;

    tooltipId = '';

    @Input()
    get width(): number {
        return this._width;
    }
    set width(w: number) {
        this._width = w;
    }

    ngOnInit(): void {
        const _uid = this._genRandomID();
        this.tooltipId = 'tooltip-' + _uid;
    }

    ngOnDestroy(): void {
        this._tooltip = null;
    }

    private _genRandomID() {
        return Math.floor((1 + Math.random()) * 0x100000000)
            .toString(16)
            .substring(1);
    }

    showTooltip($event, tooltip) {
        $event.preventDefault();
        if (!this._tooltip) {
            this._tooltip = tooltip;
        }
        if (!this._tooltip.isOpen()) {
            this._tooltip.open();
        }
    }

    dismiss($event) {
        $event.preventDefault();
        this._tooltip.close();
    }

    onOpen() {
        const root = document.querySelector('app-root');
        if (root) {
            root.setAttribute('aria-hidden', 'true');
        }
        setTimeout(() => document.getElementById(`${this.tooltipId}-close`).focus(), 0);
    }

    onClose() {
        const root = document.querySelector('app-root');
        if (root) {
            root.removeAttribute('aria-hidden');
        }
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-unsuccessful-alert',
  templateUrl: './validation-unsuccessful-alert.component.html',
  styleUrls: ['./validation-unsuccessful-alert.component.scss']
})
export class ValidationUnsuccessfulAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProspectInputContainerComponent } from './prospect-input-container/prospect-input-container.component';
import { ProspectWaitingContainerComponent } from './prospect-waiting-container/prospect-waiting-container.component';
import { ProspectCompleteContainerComponent } from './prospect-complete-container/prospect-complete-container.component';
import { DatepickerModule } from '../common-ui/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { SystemErrorComponent } from './system-error/system-error.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonUiModule } from '../common-ui/common-ui.module';
import { ProspectSuccessContainerComponent } from './prospect-success-container/prospect-success-container.component';
@NgModule({
  declarations: [
    ProspectInputContainerComponent,
    ProspectWaitingContainerComponent,
    ProspectCompleteContainerComponent,
    ProspectSuccessContainerComponent,
    SystemErrorComponent
  ],
  imports: [CommonModule, TranslateModule, FormsModule, DatepickerModule, CommonUiModule, ReactiveFormsModule],
  exports: [
    ProspectInputContainerComponent,
    ProspectWaitingContainerComponent,
    ProspectCompleteContainerComponent,
    ProspectSuccessContainerComponent
  ],
  providers: []
})
export class AgentFlowModule { }

<div #wrapper [attr.role]="role" class="bg-{{type}} {{customerNumberFlag}}" *ngIf="showAlert" tabindex="-1">
    <div class="td-row">
        <div class="td-col-xs-12" [ngClass]="{'td-col-sm-8 td-col-sm-offset-2':fullWidth, 'td-col-sm-12':!fullWidth}">
            <h3 class="td-docs">
                <span aria-hidden="true" class="td-icon icon-regular {{className}}"></span>
                <ng-container>{{title | translate}}</ng-container>
            </h3>
            <ng-container *ngIf="description">
                <p [innerHTML]="description | translate"></p>
            </ng-container>
            <ng-container *ngIf="!description">
                <ng-content></ng-content>
            </ng-container>
            <ul [class.no-desc]="!description" *ngIf="showErrorFields">
                <li *ngFor="let error of errors" [innerHTML]="error | translate"></li>
            </ul>
        </div>
    </div>
</div>
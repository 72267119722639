import { Component, OnInit } from '@angular/core';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private prospectInputService: ProspectInputService) { }

  ngOnInit(): void {
  }

  cancelFlow() {
    this.prospectInputService.invalidateSessionCache()
    .subscribe(
      () => {
        this.cleanSessionAndCloseWindow();
    },
      () => {
        this.cleanSessionAndCloseWindow();
    });
  }

  cleanSessionAndCloseWindow() {
    localStorage.clear();
    sessionStorage.clear();
    window.close();
  }

}

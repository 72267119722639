import { Component, OnInit } from '@angular/core';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.scss']
})
export class SystemErrorComponent implements OnInit {

  constructor(private prospectInputService: ProspectInputService) { }

  ngOnInit(): void {
  }

  cancelFlow() {
    this.prospectInputService.invalidateSessionCache()
    .subscribe(
      () => {
        this.cleanSessionAndCloseWindow();
    },
      () => {
        this.cleanSessionAndCloseWindow();
    });
  }

  cleanSessionAndCloseWindow() {
    localStorage.clear();
    sessionStorage.clear();
    window.close();
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { customMoment as moment } from './datepicker.util';
import { Moment } from 'moment';
import {
  DatepickerHeaderComponent,
  DATE_FORMATS,
} from './datepicker-header.component';
import * as _ from 'lodash';

export declare type MODE_TYPES =
  | 'YEAR'
  | 'MONTH'
  | 'MONTHYEAR'
  | 'WEEK'
  | ''
  | null;
export declare type OUTPUT_TYPES = 'STRING' | 'DATE' | '' | null;

@Component({
  selector: 'td-date-picker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  @Input() customFormFieldClass = 'datepicker-container';
  @Input() label = '';
  @Input() touchUi = true;
  @Input() showPlaceholder = true;
  @Input() labelledby = 'placeholder';
  @Input() placeholder;
  @Input('mask') dateMask;
  @Input() startAt;
  @Input() id = 'tdDatePicker';
  @Input() _inputValid: boolean;
  @ViewChild(MatDatepicker, { static: true }) _picker: MatDatepicker<Moment>;
  @Input() set locale(locale) {
    if (this.dateAdapter) {
      this.dateAdapter.setLocale(locale);
    }
  }
  _dateCtl: FormControl = new FormControl();
  _inputFormatMonthPicker = 'MM/YYYY';
  _inputFormatDatePicker = 'MM/DD/YYYY';
  _defaultOutputFormatMonthPicker = 'MM/YYYY';
  _defaultOutputFormatDatePicker = 'MM/DD/YYYY';

  _defaultInputMaskMonthPicker = '00/0000';
  _defaultInputMaskDatePicker = '00/00/0000';
  defaultYear = new Date();
  _output: OUTPUT_TYPES = '';
  _outputFormat = '';
  _mode: MODE_TYPES = '';
  _max: Moment;
  _min: Moment;
  _maxIn: string;
  _minIn: string;
  _customFilter: (d: Moment) => boolean;

  calenderHeader = DatepickerHeaderComponent;
  dateFocused = false;
  mouseIn = false;

  _dateInput = '';

  _validateFn: Function;

  _inputErr = {
    invalidInput: true,
  };
  _onChange = (date: any) => {};
  _onTouched = () => {};
  _onFocusLost = (datepicker: MatDatepicker<Moment>) => {};
  _datepickerClosed = (datepicker: MatDatepicker<Moment>) => {};

  constructor(
    private dateAdapter: DateAdapter<Moment>,
    private cdr: ChangeDetectorRef
  ) {}

  @Input()
  get outputFormat(): string {
    if (!this._outputFormat) {
      if (this._isMonthPicker()) {
        this._outputFormat = this._defaultOutputFormatMonthPicker;
      }
      if (this._isDatePicker()) {
        this._outputFormat = this._defaultOutputFormatDatePicker;
      }
    }
    return this._outputFormat;
  }
  set outputFormat(format: string) {
    this._outputFormat = format;
  }

  @Input()
  get output(): OUTPUT_TYPES {
    return this._output;
  }
  set output(output: OUTPUT_TYPES) {
    this._output = output;
  }

  _isOutputString() {
    return this._output === 'STRING' || this._output === '';
  }
  _isOutputDate() {
    return this._output === 'DATE';
  }
  _isMonthPicker() {
    return this._mode === 'MONTH';
  }
  _isDatePicker() {
    return this._mode === '';
  }

  @Input()
  get mode(): MODE_TYPES {
    return this._mode;
  }
  set mode(mode: MODE_TYPES) {
    this._mode = mode;
    setTimeout(() => this._setupFilter(), 300);
  }

  @Input()
  get max(): string | Date {
    return this._max ? this._max.toDate() : undefined;
  }
  set max(max: string | Date) {
    if (max) {
      const momentDate =
        max instanceof Date
          ? moment(max)
          : moment(max, this.outputFormat, true);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  @Input()
  get min(): string | Date {
    return this._min ? this._min.toDate() : undefined;
  }
  set min(min: string | Date) {
    if (min) {
      const momentDate =
        min instanceof Date
          ? moment(min)
          : moment(min, this.outputFormat, true);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  ngAfterViewInit() {
    if (
      this._isMonthPicker() &&
      this._outputFormat === this._defaultOutputFormatMonthPicker
    ) {
      this.dateMask = this._defaultInputMaskMonthPicker;
    }
    if (
      this._isDatePicker() &&
      this._outputFormat === this._defaultOutputFormatDatePicker
    ) {
      this.dateMask = this._defaultInputMaskDatePicker;
    }
    this.cdr.detectChanges();
    this._updateDateDisplay();
    this._updateInputs();
  }

  ngAfterViewChecked() {
    this.addctHiddenClass();
  }

  addctHiddenClass() {
    const cells = document.querySelectorAll(
      '.header-label, .mat-calendar-body-cell-content, .mat-calendar-body-cell, .mat-calendar-table-header th'
    );
    _.forEach(cells, (cell) => {
      cell.classList.add('ctHidden');
    });
  }

  ngOnInit() {
    this._validateFn = createDateValidator(
      this.outputFormat,
      this._isOutputDate()
    );
    if (this.startAt) {
      this.defaultYear = new Date(this.startAt, 0, 1);
    }
  }

  private _updateInputs() {
    if (this._maxIn) {
      const momentDate = moment(this._maxIn, this.outputFormat);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
    if (this._minIn) {
      const momentDate = moment(this._minIn, this.outputFormat);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  private _updateDateDisplay() {
    setTimeout(() => {
      try {
        if (this._dateCtl.value) {
          if (this._isMonthPicker() || this._isDatePicker()) {
            this._dateInput = this.dateAdapter.format(
              this._dateCtl.value,
              this.outputFormat
            );
          }
          this._propagateChange();
        }
      } catch (e) {}
    }, 0);
  }

  validate(c: FormControl) {
    if (this._dateCtl.getError('invalidInput')) {
      c.setErrors(this._inputErr);
    }
    return this._validateFn(c);
  }

  writeValue(date: Date | string): void {
    if (date) {
      const momentDate =
        typeof date === 'string'
          ? moment(date, this.outputFormat, true)
          : moment(date);
      this.setDateByType(date, momentDate);
    } else {
      this._dateCtl.setValue(undefined);
      this._dateInput = '';
    }
  }

  setDateByType(date, momentDate) {
    if (
      this._isMonthPicker() &&
      momentDate.isValid() &&
      this._isMonthEnabled(momentDate.year(), momentDate.month())
    ) {
      momentDate.set({ date: 1 });
      this._dateCtl.setValue(momentDate, { emitEvent: false });
      this._updateDateDisplay();
    } else if (this._isDatePicker() && momentDate.isValid()) {
      this._dateCtl.setValue(momentDate, { emitEvent: false });
      this._updateDateDisplay();
    } else {
      this.setInvalidDate(date);
    }
  }

  setInvalidDate(date) {
    this._dateCtl.setValue(date);
    this._dateCtl.setErrors(this._inputErr);
    this._inputValid = false;
    this._propagateChange();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState1(isDisabled: boolean): void {
    isDisabled
      ? (this._picker.disabled = true)
      : (this._picker.disabled = false);

    isDisabled ? this._dateCtl.disable() : this._dateCtl.enable();
  }

  onMonthSelected(chosenMonthDate: Moment, datepicker: MatDatepicker<Moment>) {
    if (this._isDatePicker()) {
      return;
    }
    datepicker.close();
    if (
      !this._isMonthEnabled(chosenMonthDate.year(), chosenMonthDate.month())
    ) {
      return;
    }

    if (this._max && chosenMonthDate.diff(this._max, 'month') > 0) {
      chosenMonthDate = this._max.clone();
    }

    if (this._min && this._min.diff(chosenMonthDate, 'month') > 0) {
      chosenMonthDate = this._min.clone();
    }
    chosenMonthDate.set({ date: 1 });
    this._dateCtl.setValue(chosenMonthDate);
    this._updateDateDisplay();
    if (this._isOutputString()) {
      const d = chosenMonthDate.format(this._outputFormat);
      this._onChange(d);
    } else if (this._isOutputDate()) {
      this._onChange(chosenMonthDate);
    }
    this._onTouched();
  }

  private _isMonthEnabled(year: number, month: number) {
    if (
      month === undefined ||
      month === null ||
      this._isDateAfterMax(year, month) ||
      this._isDateBeforeMin(year, month)
    ) {
      return false;
    }

    if (!this._customFilter) {
      return true;
    }

    const firstOfMonth = moment([year, month, 1]);
    for (const date = firstOfMonth; date.month() === month; date.add(1, 'd')) {
      if (this._customFilter(date)) {
        return true;
      }
    }

    return false;
  }

  private _isDateAfterMax(year: number, month: number) {
    if (this._max) {
      const maxYear = this._max.year();
      const maxMonth = this._max.month();
      return year > maxYear || (year === maxYear && month > maxMonth);
    }
    return false;
  }

  private _isDateBeforeMin(year: number, month: number) {
    if (this.min) {
      const minYear = this._min.year();
      const minMonth = this._min.month();
      return year < minYear || (year === minYear && month < minMonth);
    }
    return false;
  }

  openDatepicker(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      datepicker.open();
      setTimeout(() => {
        const selector = '#datepickerCloseButton';
        const ele = document.querySelector(selector);
        ele && (ele as HTMLElement).focus();
      }, 100);
    }
    this.defaultYear =
      this._dateCtl.value !== undefined
        ? this._dateCtl.value
        : new Date(this.startAt, 0, 1);
  }

  datepickerClosed(datepicker: MatDatepicker<Moment>) {
    this._onFocusLost(datepicker);
    this._datepickerClosed(datepicker);
    this.updateInputValue();
  }

  updateInputValue() {
    if (this._dateCtl.value !== undefined) {
      this._dateInput = this.dateAdapter.format(
        this._dateCtl.value,
        this._outputFormat
      );
      this.inputBlurred();
      this.defaultYear = this._dateCtl.value;
    }
  }

  private _setupFilter() {}

  private _validateInput() {
    if (this._dateInput) {
      const momentDate = moment(this._dateInput, this._outputFormat, true);
      if (momentDate.isValid()) {
        this._dateCtl.setValue(momentDate, { emitEvent: false });
        if (this._dateCtl.status === 'INVALID') {
          this._inputValid = false;
        } else {
          this._inputValid = true;
        }
      } else {
        this._dateCtl.setValue(undefined);
        this._dateCtl.setErrors(this._inputErr);
        this._inputValid = false;
      }
    } else {
      this._dateCtl.setValue(undefined);
      this._dateInput = '';
      this._inputValid = false;
    }
  }

  inputBlurred() {
    this.dateFocused = false;
    this.mouseIn = false;
    this._dateCtl.setValue(moment(this._dateInput, this._outputFormat, true));
    this._validateInput();
    this._propagateChange();
    this._onTouched();
  }

  _propagateChange() {
    if (this._isOutputString()) {
      const d = this._dateInput ? this._dateInput : undefined;
      this._onChange(d);
    } else if (this._isOutputDate()) {
      const d = this._dateCtl.value
        ? (this._dateCtl.value as Moment)
        : undefined;
      if (d) {
        this._onChange(d);
      }
    }
  }

  triggerMouseentered() {
    this.mouseIn = true;
  }
  triggerMouseleft() {
    this.mouseIn = false;
  }
  triggerFocused() {
    this.dateFocused = true;
  }
  triggerBlurred() {
    this.dateFocused = false;
    this.mouseIn = false;
  }

  get focused(): boolean {
    return this.dateFocused || this.mouseIn;
  }
}

export const createDateValidator = (formatting, isOutputDate) => {
  return (c: FormControl) => {
    const d = c.value;
    const momentDate = moment(d, formatting, true);
    const err = {
      dateError: {
        given: c.value,
        formatting,
      },
    };
    if (c.getError('invalidInput')) {
      return err;
    }
    if (typeof d === 'string') {
      return momentDate.isValid() ? null : err;
    }
    if (isOutputDate) {
      if (d === undefined || d === null || d instanceof Date) {
        return null;
      }
      return err;
    }
    if (d === undefined || d === null || d === '') {
      return null;
    } else {
      return momentDate.isValid() ? null : err;
    }
  };
};

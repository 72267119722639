import { Injectable } from '@angular/core';

import { SubmitProspectRsModel } from 'src/app/models/agent-flow/api/response/submit-prospect-rs-model';
import { InputIndividualModel } from 'src/app/models/agent-flow/api/common/input-individual.model';
import { SubmitProspectRqModel } from 'src/app/models/agent-flow/api/request/submit-prospect-rq-model';
import { IdentityProofingResultRsModel } from 'src/app/models/agent-flow/api/response/identity-proofing-result-rs-model';
import { HttpClient } from '@angular/common/http';
import { ProspectPageInputModel } from 'src/app/models/agent-flow/ui/prospect-page-input.model';
import { InputAddressModel } from 'src/app/models/agent-flow/api/common/input-address.model';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CaptureConsentRqModel } from 'src/app/models/agent-flow/api/request/capture-consent-rq-model';
import { CaptureConsentRsModel } from 'src/app/models/agent-flow/api/response/capture-consent-rs-model';

@Injectable({
  providedIn: 'root',
})
export class ProspectInputService {
  constructor(private http: HttpClient, private router: Router) { }

  private submitProspectDataPath: string = "/dig-bff/employeewebvisits/prospects";
  private identityProofingResultDataPath: string = "/dig-bff/employeewebvisits/identityproofingresult";
  private invalidateSessionDataPath: string = "/dig-bff/employeewebvisits/sessioninformation/cancel";
  private invalidateClientCacheDataPath: string = "/dig-bff/employeewebvisits/sessioninformation/cancelclient";
  private captureConsentDataPath: string = "/dig-bff/employeewebvisits/consentevents";

  public submitProspectData(prospect: ProspectPageInputModel) {
    const sanitizedPrimaryTelephoneNum: string = prospect.telephoneNum.replace(/-/g, "");
    const sanitizedSecondaryTelephoneNum: string = prospect.secondaryTelephoneNum != null ? prospect.secondaryTelephoneNum.replace(/-/g, "") : null;
    const individualConst: InputIndividualModel = {givenName: prospect.givenName, middleName: prospect.middleName || '', familyName: prospect.familyName, birthDt: prospect.birthDt, telephoneNum: sanitizedPrimaryTelephoneNum, secondaryTelephoneNum: sanitizedSecondaryTelephoneNum || '', emailAddress: prospect.emailAddress};

    const addressConst: InputAddressModel = {streetNum: prospect.streetNum, streetName: prospect.streetName, unitNum: prospect.unitNum || '', unitTypeCd: prospect.unitTypeCd || '', cityName: prospect.cityName, territoryCd: prospect.territoryCd, postalCodeNum: prospect.postalCodeNum};
    const prospectConst: SubmitProspectRqModel = {inputIndividual: individualConst, inputAddress: addressConst};
    return this.http.post<SubmitProspectRsModel>(this.submitProspectDataPath, prospectConst, { observe: 'response' })
      .pipe(
        catchError(
          error => {
            this.router.navigateByUrl('/agent-error');
            return throwError("");
        })
      );
  }

  public pollForIdentityProofingResult() {
    return this.http.get<IdentityProofingResultRsModel>(this.identityProofingResultDataPath, { observe: 'response' }).pipe(
      catchError(
        error => {
          this.router.navigateByUrl('/session-expired');
          return throwError("");
      })
    );
  }

  public invalidateSessionCache() {
    return this.http.get(this.invalidateSessionDataPath);
  }

  public invalidateClientCache() {
    return this.http.get(this.invalidateClientCacheDataPath);
  }

  public captureConsentData(customerNumber: String){
    const consentRqConst: CaptureConsentRqModel = {partyId: customerNumber};

    return this.http.post<CaptureConsentRsModel>(this.captureConsentDataPath, consentRqConst, {observe: 'response'})
      .pipe(
      );
  }
}

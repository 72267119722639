import { EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, AfterViewInit, Injectable, Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ErrorAlertService } from '../error-alert/error-alert.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class InputBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() formGroupControl: FormGroup;
    @Input() controlName: string;
    @Input() baseKey: string;
    @Input() hasTooltip = false;
    @Input() tooltipText: string;
    @Input() labelLink;
    @Input() errorPrefix;
    @Input() errorLabel;
    @Input() getErrorFn;
    @Input() errorGlobal = true;
    @Input() errorInline = true;
    @Input() isReadonly = false;
    @Input() idSuffix = '';

    destroy$: Subject<boolean> = new Subject<boolean>();


    // tslint:disable: variable-name
    private _labelText;
    private _highlight;


    @Input() highlightFn = (controlName, control) => '';
    get highlight() {
        this._highlight = !((this.control || {}).touched) && this.highlightFn && this.highlightFn(this.controlName, this.control) || '';
        return this._highlight;
    }

    @Input()
    get labelText() {
        return this._labelText || (this.baseKey + '.' + this.controlName + '.label');
    }
    set labelText(labelText) {
        this._labelText = labelText;
    }


    @Output() changeEvent: EventEmitter<Event> = new EventEmitter();
    @Output() touchEvent: EventEmitter<Event> = new EventEmitter();
    @Output() blurEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() focusEvent: EventEmitter<any> = new EventEmitter<any>();

    get control() {
        return (this.formGroupControl?.controls || {})[this.controlName];
    }
    get error() {
        if (this.getErrorFn) {
            return this.getErrorFn(this);
        }
        let error;
        if (this.control.invalid && !this.isReadonly) {
            const errorKey = Object.keys(this.control.errors)[0];
            error = (this.errorPrefix || this.labelText) + '.errors.' + errorKey;
        }
        return error;
    }



    constructor(private errorAlertService: ErrorAlertService) { }


    ngOnInit() { }

    ngAfterViewInit() {
        if (this.control) {
            this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateErrors());
            this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateErrors());
        }
    }

    ngOnChanges(changes) {
        if (this.control) {
            this.updateErrors();
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        if (this.control) {
            this.updateErrors(true);
        }
    }

    onChange(event?) {
        this.changeEvent.emit(event || this.control.value);
    }

    onTouch(event?) {
        this.touchEvent.emit(event || this.control.value);
    }

    onFocus(event?) {
        this.focusEvent.emit(event || this.control.value);
    }

    onBlur(event?) {
        this.blurEvent.emit(event || this.control.value);
    }

    isEmpty(control) {
        return control.value === null || control.value === undefined || control.value === '';
    }

    isInvalid(control) {
        return !control || (control.errors && (control.dirty || control.touched));
    }
    isDisabled(control) {
        return !control || control.disabled;
    }

    isRequired(control) {
        if (control && control.validator) {
            const validator = control.validator(new FormControl());
            return (validator && validator.required);
        }
        return false;
    }

    updateErrors(forceDelete = false) {
        if (this.control.invalid && !forceDelete && !this.isReadonly) {
            this.errorAlertService.addError(this.baseKey + '.' + this.controlName + this.idSuffix,
                this.errorLabel || this.labelText, this.error, this.errorGlobal, this.errorInline);
        } else {
            this.errorAlertService.removeError(this.baseKey + '.' + this.controlName + this.idSuffix);
        }
    }
}

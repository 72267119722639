import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { interval, Subscription, timer } from 'rxjs';
import { retryWhen, switchMap, takeWhile } from 'rxjs/operators';
import { CancelModalComponent } from 'src/app/common-ui/cancel-modal/cancel-modal.component';
import { ProspectPageInputModel } from 'src/app/models/agent-flow/ui/prospect-page-input.model';
import { AgentSessionService } from 'src/app/services/agent-session-service.service';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
import { GenericRetryStrategy } from 'src/app/services/common/generic-retry-strategy';
import { ModalService } from 'src/app/services/modal.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-prospect-waiting-container',
  templateUrl: './prospect-waiting-container.component.html',
  styleUrls: ['./prospect-waiting-container.component.scss']
})
export class ProspectWaitingContainerComponent implements OnInit {

  prospectInput: ProspectPageInputModel = {};
  timeInterval: Subscription;
  prospectComplete: boolean = false;

  constructor(private agentSessionService: AgentSessionService, private prospectInputService: ProspectInputService, private router: Router, private dialog: MatDialog, private modalService: ModalService, private translate: TranslatePipe,) {}

  ngOnInit(): void {
    this.prospectInput = this.agentSessionService.prospectInput;
    this.timeInterval = timer(0, 30000).pipe(
    switchMap(() => this.prospectInputService.pollForIdentityProofingResult()), retryWhen(GenericRetryStrategy()), takeWhile(response => response.body.nextStateName != "IDENTITY_PROOFING_COMPLETED", true)
    ).subscribe(
      response => {
        if (response.body.prospectInput != null) {
          this.prospectInput = response.body.prospectInput;
          this.agentSessionService.prospectInput = response.body.prospectInput;
          this.prospectInput.telephoneNum = this.formatPhoneNumber(this.prospectInput.telephoneNum);
          this.prospectInput.secondaryTelephoneNum = this.formatPhoneNumber(this.prospectInput.secondaryTelephoneNum);
        }
        if(response.body.nextStateName == "IDENTITY_PROOFING_COMPLETED") {
          this.agentSessionService.matchingResultCd = response.body.matchingResultCd;
          this.agentSessionService.otherFinancialInstitutionBundleData = response.body.otherFinancialInstitutionBundleData;
          this.agentSessionService.creditBureauBundleData = response.body.creditBureauBundleData;
          this.prospectComplete = true;
        }
      },
      errorMsg => {
        this.router.navigateByUrl('/agent-error');
      }
    );
  }

  ngOnDestroy() {
    this.timeInterval.unsubscribe();
 }
  onRefresh(): void {
    this.router.navigateByUrl('/complete');
  }

  openCancelModal(){
    var optionsData = {
      title: this.translate.transform("agent-flow.cancel-confirmation-modal.primary-header"),
      body: [{ content: this.translate.transform("agent-flow.cancel-confirmation-modal.information-body-copy") }],
      buttons: [{ label: this.translate.transform("agent-flow.cancel-confirmation-modal.resumeBtn-label"), id: 'resumeBtn', className: 'td-button cancel-button td-button-clear-green' },
      { label: this.translate.transform("agent-flow.cancel-confirmation-modal.cancelBtn-label"), id: 'cancelBtn', className: 'td-button td-button-block td-button-secondary continue-button' }]
    };

    var options = { data: optionsData, disableClose: true, autoFocus: true }
    this.modalService.openModal(CancelModalComponent, options);
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return [match[1], match[2], match[3]].join('-');
    }
    return null;
  }
}

<div class="input-group" [formGroup]="formGroupControl" [class.has-error]="isInvalid(control)">
    <div class="label-elements">
        <td-input-label
            [formGroupControl]="formGroupControl"
            [controlName]="controlName"
            [labelText]="labelText"
            [baseKey]="baseKey"
            [hasTooltip]="hasTooltip"
            [tooltipText]="tooltipText"
            [highlight]="highlight"
            [labelLink]="labelLink"
        ></td-input-label>
        <input
            [type]="type"
            [formControlName]="controlName"
            [id]="controlName+idSuffix"
            [attr.name]="controlName+idSuffix"
            class="ctHidden form-control"
            [class.highlight]="!!highlight"
            [placeholder]="placeholder"
            [attr.aria-label]="labelText | translate"
            [attr.aria-describedby]="baseKey + '.' + controlName + '.inputError'"
            (change)="onChange()"
            (blur)="onBlur($event)"
            (focus)="onFocus($event)"
            [attr.minLength]="minLength"
            [attr.maxLength]="maxLength"
        />
        <td-error-message [formGroup]="formGroupControl" [controlName]="controlName" [baseKey]="baseKey" [labelText]="labelText" [idSuffix]="idSuffix"></td-error-message>
    </div>
</div>

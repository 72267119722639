import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { TimeoutDialogComponent } from './timeout-dialog/timeout-dialog.component';
import { TimeoutDialogModule } from './timeout-dialog/timeout-dialog.module';
import { MaxLimitComponent } from './max-limit/max-limit.component';
import { ErrorAlertComponent } from './error-alert/error-alert.component';
import { InputLabelComponent } from './input-label/input-label.component';
import { InputTextComponent } from './input-text/input-text.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CancelConfirmComponent } from './cancel-confirm/cancel-confirm.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ModalConfigModule } from './modal/modal.module';
import { ValidationSuccessAlertComponent } from './validation-success-alert/validation-success-alert.component';
import { ValidationUnsuccessfulAlertComponent } from './validation-unsuccessful-alert/validation-unsuccessful-alert.component';
import { UnsuccessfulMatchInstructionsComponent } from './unsuccessful-match-instructions/unsuccessful-match-instructions.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { CifInputComponent } from './cif-input/cif-input.component';
import { CreditBureauInformationComponent } from './credit-bureau-information/credit-bureau-information.component';
import { FiInformationComponent } from './fi-information/fi-information.component';
import { CancelWithoutCifDialogModule } from './cancel-without-cif-dialog/cancel-without-cif-dialog.module';
import { CancelWithoutCifDialogComponent } from './cancel-without-cif-dialog/cancel-without-cif-dialog.component';
import { ModalButtonsComponent } from './modal/buttons/buttons.component';
import { ModalCloseComponent } from './modal/close/close.component';
import { ModalTitleComponent } from './modal/title/title.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SubmitConfirmModalComponent } from './submit-confirm-modal/submit-confirm-modal.component';
import { InputSelectComponent } from './input-select/input-select.component';

@NgModule({
  declarations: [SessionExpiredComponent, ErrorAlertComponent, InputLabelComponent, InputTextComponent, ErrorMessageComponent, TooltipComponent, MaxLimitComponent,ValidationSuccessAlertComponent, ValidationUnsuccessfulAlertComponent, UnsuccessfulMatchInstructionsComponent, GenericErrorComponent, CifInputComponent, CreditBureauInformationComponent, FiInformationComponent, AccessDeniedComponent, CancelModalComponent, SubmitConfirmModalComponent, CancelConfirmComponent, InputSelectComponent],
  imports: [CommonModule, LayoutModule, TimeoutDialogModule, TranslateModule, CancelWithoutCifDialogModule,FormsModule, ReactiveFormsModule, NgbModule, MatDialogModule, MatButtonModule, MatIconModule, ModalConfigModule, NgxMaskModule.forRoot({
    dropSpecialCharacters: false,
    clearIfNotMatch: false
})],
  exports: [TimeoutDialogComponent, HeaderComponent, FooterComponent, ErrorAlertComponent, InputLabelComponent, InputTextComponent,CancelWithoutCifDialogComponent, ValidationSuccessAlertComponent, ValidationUnsuccessfulAlertComponent, UnsuccessfulMatchInstructionsComponent, CifInputComponent, CreditBureauInformationComponent, FiInformationComponent, CancelConfirmComponent, InputSelectComponent]
})

export class CommonUiModule { }

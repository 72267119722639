<div #wrapper [attr.role]="role" tabindex="-1">
<h2 class="text-center">
    <span
      class="td-icon icon-regular td-icon-warning"
      style="font-size: 30px; vertical-align: -3px"
    ></span>
    {{ "agent-flow.prospect-complete-container.next-steps-header" | translate }}
  </h2>
  <div>
    <div class="td-row">
      <div class="text-center">
        <p class="text-center">
          {{
            "agent-flow.prospect-complete-container.instructions-body-copy"
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
</div>


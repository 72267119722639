<div class="td-row">
  <div class="td-col-xs-12">
    <td-error-alert></td-error-alert>
  </div>
  <div
    class="td-col-xs-12
      td-col-sm-10
      td-col-sm-offset-1
      td-col-md-8
      td-col-md-offset-2">
    <h1 class="text-center" tabindex="-1">{{"agent-flow.prospect-input-container.primary-header"| translate}}</h1>
    <h2 class="text-center">{{"agent-flow.prospect-input-container.personal-info-header"| translate}}</h2>

    <form [formGroup]="prospectForm" >
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
            <td-input-text
                [controlName]="givenNameControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm"
                [hasTooltip]="true">
            </td-input-text>
        </div>
        <div class="td-col-xs-12 td-col-sm-6">
          <td-input-text
              [controlName]="middleNameControlName"
              [baseKey]="baseKey"
              [formGroupControl]="prospectForm">
          </td-input-text>
        </div>
      </div>
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
          <td-input-text
              [controlName]="familyNameControlName"
              [baseKey]="baseKey"
              [formGroupControl]="prospectForm"
              [hasTooltip]="true">
          </td-input-text>
        </div>
      </div>
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
          <div class="input-group">
            <div class="label-elements">
<!--              <label [for]="birthDtControlName">{{"agent-flow.prospect-input-container.birthDt.label"| translate}}</label>-->
<!--              <td-date-picker-->
<!--              [id]="birthDtControlName"-->
<!--              [formControlName]="birthDtControlName"></td-date-picker>-->
              <td-input-text
                [controlName]="birthDtControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm"
                [placeholder]="(baseKey + '.' + birthDtControlName +'.placeholder') | translate">
              </td-input-text>
            </div>
          </div>
        </div>
      </div>
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
          <td-input-text
              [controlName]="telephoneNumControlName"
              [baseKey]="baseKey"
              [formGroupControl]="prospectForm"
              [placeholder]="(baseKey + '.' + telephoneNumControlName +'.placeholder') | translate">
          </td-input-text>
        </div>
      </div>
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
          <td-input-text
              [controlName]="secondaryTelephoneNumControlName"
              [baseKey]="baseKey"
              [formGroupControl]="prospectForm">
          </td-input-text>
        </div>
      </div>
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
          <td-input-text
              [controlName]="emailAddressControlName"
              [baseKey]="baseKey"
              [formGroupControl]="prospectForm">
          </td-input-text>
        </div>
      </div>
      <div class="td-row">
        <div class="td-col-xs-12 td-col-sm-6">
          <td-input-text
              [controlName]="confirmEmailAddressControlName"
              [baseKey]="baseKey"
              [formGroupControl]="prospectForm">
          </td-input-text>
        </div>
      </div>
      <h2 class="text-center">{{"agent-flow.prospect-input-container.address-header"| translate}}</h2>
      <div class="address-panel">
        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
              <td-input-text
                  [controlName]="streetNumControlName"
                  [baseKey]="baseKey"
                  [formGroupControl]="prospectForm">
              </td-input-text>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <td-input-text
                [controlName]="unitNumControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm"
                (change)="onUnitNumChange($event)">
            </td-input-text>
          </div>
        </div>
        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <td-input-text
                [controlName]="streetNameControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm">
            </td-input-text>
          </div>
        </div>
        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <td-input-select
                [controlName]="unitTypeCdControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm"
                [options]="(baseKey + '.' + unitTypeCdControlName + '.options') | translate"
                [highlightFn]=""
                (change)="onUnitTypeChange($event)">
              </td-input-select>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <td-input-text
                [controlName]="cityNameControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm">
            </td-input-text>
          </div>
        </div>
        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
              <td-input-select
                [controlName]="territoryCdControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm"
                [options]="(baseKey + '.' + territoryCdControlName +'.options') | translate"
                [highlightFn]="">
              </td-input-select>
          </div>
          <div class="td-col-xs-12 td-col-sm-6">
            <td-input-text
                [controlName]="postalCodeNumControlName"
                [baseKey]="baseKey"
                [formGroupControl]="prospectForm"
                [placeholder]="(baseKey + '.' + postalCodeNumControlName +'.placeholder') | translate">
            </td-input-text>
          </div>
        </div>
        <div class="td-row">
          <div class="td-col-xs-12 td-col-sm-6">
            <div class="input-group">
              <div class="label-elements">
                <label for="country">{{ "agent-flow.prospect-input-container.country-label" | translate }}</label>
                <output id="country">{{country}}</output>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-offset-4 td-col-sm-4">
      <div class="btn-container">
        <button #btn
          class="td-button td-button-block td-button-secondary continue-button"
          id="continueBtn"
          type="submit"
          (click)="openSubmitModal()"
          >
          {{ "agent-flow.prospect-input-container.submitBtn-label" | translate }}
        </button>
      </div>
      <div class="btn-container">
        <a class="td-link-inline cancel-button" href="javascript:void(0)" id="btnCancel"
                (click)="openCancelModal()" (keydown.enter)="openCancelModal()" allowunload="true">{{
                "agent-flow.prospect-input-container.cancelBtn-label" | translate
                }}</a>
      </div>
    </div>
  </div>
</div>

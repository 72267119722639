<div class="container">
  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="fullName">
            {{
              "agent-flow.prospect-complete-container.vMeName-label" | translate
            }}</label
          >
          <output id="fullName" [textContent]="creditBureauBundleData?.fullName"></output>
        </div>
      </div>
    </div>
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="dob">{{
            "agent-flow.prospect-complete-container.vMeDateOfBirth-label"
              | translate
          }}</label>
          <output id="dob" [textContent]="creditBureauBundleData?.birthDt"></output>
        </div>
      </div>
    </div>
  </div>

  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="address">{{
            "agent-flow.prospect-complete-container.vMeAddress-label"
              | translate
          }}</label>
          <output id="address" [textContent]="creditBureauBundleData?.address"></output>
        </div>
      </div>
    </div>
  </div>

  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="uniqueId">
            {{
              "agent-flow.prospect-complete-container.vMeUniqueId-label"
                | translate
            }}</label
          >
          <output id="uniqueId" [textContent]="creditBureauBundleData?.uniqueID"></output>
        </div>
      </div>
    </div>
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="docSource">{{
            "agent-flow.prospect-complete-container.vMeDocumentSource-label"
              | translate
          }}</label>
          <output id="docSource" [textContent]="creditBureauBundleData?.documentSrc"></output>
        </div>
      </div>
    </div>
  </div>

  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="cifCode">
            {{
              "agent-flow.prospect-complete-container.vMeCifCode-label"
                | translate
            }}</label
          >
          <output id="cifCode" [textContent]="creditBureauBundleData?.CIFCode"></output>
        </div>
      </div>
    </div>
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="issuingCountry">{{
            "agent-flow.prospect-complete-container.vMeIssuingCountry-label"
              | translate
          }}</label>
          <output id="issuingCountry" [textContent]="creditBureauBundleData?.issuingCountry"></output>
        </div>
      </div>
    </div>
  </div>

  <div class="td-row">
    <div class="td-col-xs-12 td-col-sm-6">
      <div class="input-group">
        <div class="label-elements">
          <label for="verificationDate">{{
            "agent-flow.prospect-complete-container.vMeVerificationDate-label"
              | translate
          }}</label>
          <output id="verificationDate" [textContent]="creditBureauBundleData?.verificationDt"></output>
        </div>
      </div>
    </div>
  </div>
</div>

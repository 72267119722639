<div class="td_rq_header-nav td-header-nav">
  <header class="td-header-desktop"><ng-container *ngTemplateOutlet="header"></ng-container></header>
  <header class="td-header-mobile"><ng-container *ngTemplateOutlet="header"></ng-container></header>
  <div class="td-container td-padding-none">
      <ng-content></ng-content>
  </div>
</div>

<ng-template #header>
  <div class="td-skip"><a href="javascript:void(0)" (keydown.space)="skipToMain($event)" (click)="skipToMain($event)">{{ 'common-ui.header.skipToMain' | translate }}</a> </div>
      <div class="td-utility-toggle"></div>
      <div class="td-nav-primary">
          <div class="td-container">
              <div class="td-section-left">
                  <div class="td-logo">
                      <img src="assets/img/td-logo.png" srcset="" alt="{{ 'common-ui.header.tdLogo.accessibilityTag' | translate }}" />
                      <img src="assets/img/secureBtn.svg" srcset="" alt="{{ 'common-ui.header.secure.accessibilityTag' | translate }}" />
                  </div>
              </div>
          </div>
      </div>
</ng-template>

<div class="header-close">
    <span
        #datepickerCloseButton
        id="datepickerCloseButton"
        class="td-icon icon-regular td-icon-close text-right"
        (click)="dismiss()"
        (keyup.enter)="dismiss()"
        (keyup.space)="dismiss()"
        role="button"
        tabindex="0"
        [attr.aria-label]=" labels.closeBtn | translate"
    ></span>
</div>

<div class="datepicker-header decades-view" *ngIf="!toSelectYear">
    <h2 class="datepicker-header month-view" *ngIf="toSelectMonth">
        <button mat-icon-button (click)="gotoDecades()" class="previous"><span class="td-icon icon-small td-icon-arrowLeft"></span></button> <span class="header-label chosen">{{ yearLabel }}</span>
    </h2>
    <h2 class="datepicker-header date-view" *ngIf="toSelectDate">
        <button mat-icon-button (click)="gotoYears()" class="chosen"><span class="td-icon icon-small td-icon-arrowLeft"></span></button> <span class="header-label chosen">{{ monthYearLabel }}</span>
    </h2>
</div>
<div class="datepicker-header decades-view" *ngIf="toSelectYear">
    <h1 class="pickyear">{{ labels.header | translate }}</h1>
    <h2 class="header-decades-navi">
        <button mat-icon-button (click)="getDecade(-1)" class="previous" [attr.aria-label]="labels.prevBtn | translate"><span class="td-icon icon-small td-icon-arrowLeft"></span></button>
        <span class="header-decades-label ctHidden">{{ periodLabel }}&apos;s</span>
        <button mat-icon-button (click)="getDecade(1)" class="next" [attr.aria-label]="labels.nextBtn | translate"><span class="td-icon icon-small td-icon-arrowRight"></span></button>
    </h2>
    <div class="years-box">
        <table class="mat-calendar-table">
            <tbody id="multiyear-picker" class="mat-calendar-body" role="grid" (keydown)="_handleCalendarBodyKeydown($event)">
                <tr *ngFor="let row of yearRows; let rowIndex = index" role="row">
                    <td
                        *ngFor="let item of row; let colIndex = index"
                        role="gridcell"
                        class="ctHidden mat-calendar-body-cell"
                        [tabindex]="isYearSelected(item.value) ? 0 : -1"
                        [class.mat-calendar-body-disabled]="isYearOutOfRange(item.value)"
                        [class.mat-calendar-body-active]="isYearSelected(item.value)"
                        [attr.aria-label]="item.ariaLabel"
                        [attr.aria-disabled]="!item.enabled || null"
                        [attr.aria-selected]="isYearSelected(item.value)"
                        (click)="onYearSelected(item.value)"
                        [style.width.%]="100 / 5"
                    >
                        <div class="ctHidden mat-calendar-body-cell-content" [class.mat-calendar-body-selected]="isYearSelected(item.value)" [class.mat-calendar-body-today]="isCurrentYear()">
                            {{ item.displayValue }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

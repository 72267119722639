import { Component, OnInit, Input } from '@angular/core';
import { ErrorAlertService } from '../error-alert/error-alert.service';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
  selector: 'td-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent extends InputBaseComponent implements OnInit {
  @Input() options = [];
  @Input() defaultOption = 'common-ui.dropdown.label';

  constructor(errorAlertService: ErrorAlertService) { super(errorAlertService); }

  ngOnInit(): void {
    super.ngOnInit();
        if (this.isEmpty(this.control)) {
            this.control.setValue('');
        }
  }

}

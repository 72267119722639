import { Pipe, PipeTransform } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { ConfigurationService } from 'src/app/services/api/configuration.service';

const counter = timer(0, 1000);

@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss']
})
export class TimeoutDialogComponent implements OnInit, OnDestroy {

  countDown;
  counter = 0;
  tick = 1000;

  constructor(private dialogRef: MatDialogRef<TimeoutDialogComponent>, private router: Router, private configService: ConfigurationService) {
    this.counter = configService.getTimeoutInSeconds();
  }

  ngOnDestroy(): void {
    this.countDown.unsubscribe();
  }

  ngOnInit(): void {
    this.countDown = this.getCounter(this.tick)
      .subscribe(() => {
        this.counter--;
        if (this.counter == 0) {
          this.exitWithoutClosing();
          this.router.navigateByUrl('/session-expired');
        }
      });
  }

  getCounter(tick) {
    return timer(0, tick)
  }

  exitWithoutClosing() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.dialogRef.close("EXIT_WITHOUT_CLOSING");
  }

  exit() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.dialogRef.close("EXIT");
  }

  resume() {
    this.dialogRef.close("RESUME");
  }


}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor((value % 3600) / 60);
    if (value <= 0) {
      return '00:00';
    }
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}


import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ErrorAlertService } from '../error-alert/error-alert.service';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {
  @Input() type = 'danger';
  @Input() fullWidth = true;
  @Input() title = '';
  @Input() showAlert = false;
  role;

  @ViewChild('wrapper') wrapper: ElementRef;

  get className() {
    switch (this.type) {
        case 'danger':
            return 'td-icon-close icon-danger';
        case 'warning':
            return 'td-icon-error icon-warning';
    }
}

constructor(public errorAlertService: ErrorAlertService, private focusMonitor: FocusMonitor) { }

  ngOnInit(): void {
    this.role = this.type === 'danger' ? 'alert' : null;
    this.errorAlertService.submitted.subscribe(submitted => {
      setTimeout(() => {
          const wrapper = this.wrapper?.nativeElement;
          if (wrapper) {
              wrapper.removeAttribute('role');
              wrapper.setAttribute('role', this.role);
              this.focusMonitor.focusVia(wrapper, 'program');
          }
      }, 100);
  });
  }
}

<label [for]="controlName" [id]="controlName+'_label'">
    <span *ngIf="!!highlight" class="label-arrow" >
        <span class="td-forscreenreader">{{ 'common-ui.label.warning' | translate }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="7" viewBox="0 0 15 7"><defs><style>.abc{fill:#1c1c1c;}</style></defs><path class="abc" d="M606.072,190.226a.542.542,0,0,1,.39-.157h12.657l-1.952-2.194a.4.4,0,0,1-.092-.157.632.632,0,0,1-.031-.2.566.566,0,0,1,.154-.4.52.52,0,0,1,.4-.167.461.461,0,0,1,.205.052,1.213,1.213,0,0,1,.186.115l2.753,3.134a.379.379,0,0,1,.123.167.553.553,0,0,1,.042.21.524.524,0,0,1-.042.219.593.593,0,0,1-.123.178l-2.753,2.759a.584.584,0,0,1-.175.125.521.521,0,0,1-.216.042.541.541,0,0,1-.205-.042.528.528,0,0,1-.185-.125,1.437,1.437,0,0,1-.113-.178.452.452,0,0,1-.052-.22.47.47,0,0,1,.052-.209,1.158,1.158,0,0,1,.113-.188l1.828-1.819H606.462a.545.545,0,0,1-.39-.156.512.512,0,0,1-.165-.387v-.021A.509.509,0,0,1,606.072,190.226Z" transform="translate(-605.907 -186.956)"/></svg>
    </span>
    <span class='label-text' [class.text-bold]="!!highlight" [innerHTML]="labelText | translate"></span>
    <span *ngIf="!!highlight" class='label-error'> {{ highlight | translate }}</span>
    <span *ngIf="isRequired"> {{ 'common-ui.label.required' | translate}}</span>
    <span *ngIf="isOptional && !highlight"> {{ 'common-ui.label.optional' | translate}}</span>
    <span *ngIf="!!labelLink">
        <span> | </span>
        <a (click)="labelLink.click()" href="javascript:void(0)" type="button" role="button" class="td-link-inline">{{ labelLink.label | translate}}</a>
    </span>
    <td-tooltip [hasTooltip]="hasTooltip" [labelText]="labelText" [tooltipText]="tooltipText"></td-tooltip>
</label>

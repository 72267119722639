import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare const _: any;

@Injectable({ providedIn: 'root' })
export class ErrorAlertService {
    submitted = new BehaviorSubject(0);
    errors = new BehaviorSubject({});
    errorsCount = 0;

    constructor() { }

    clear() {
        this.submitted = new BehaviorSubject(0);
        this.errors = new BehaviorSubject({});
    }
    submit() {
        this.submitted.next(this.submitted.value + 1);
    }

    addError(controlName, labelText, error, global, inline) {
        const errors = this.errors.value;
        errors[controlName] = { labelText, error, global, inline };
        this.errors.next(errors);
        this.errorsCount = Object.keys(errors).length;
    }

    getError(controlName, type: 'global' | 'inline') {
        const errors = this.errors.value;
        if (errors[controlName]) {
            return errors[controlName][type] === true ? errors[controlName] : {};
        }
        return {};
    }

    removeError(controlName) {
        const errors = this.errors.value;
        delete errors[controlName];
        this.errors.next(errors);
        this.errorsCount = Object.keys(errors).length;
    }
}

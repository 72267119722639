<h2 class="text-center">
  <span
    class="td-icon icon-regular td-icon-warning"
    style="font-size: 30px; vertical-align: -3px"
  ></span>
  {{ "agent-flow.prospect-complete-container.next-steps-header" | translate }}
</h2>
<div class="td-row center-block">
  <div class="td-col-xs-8 td-col-sm-6">
    <ul>
      <li>{{
          "agent-flow.prospect-complete-container.first-step-body-copy"
            | translate
        }}
      </li>
      <li>{{
          "agent-flow.prospect-complete-container.second-step-body-copy"
            | translate
        }}
      </li>
    </ul>
  </div>
</div>

<br />

<form [formGroup]="prospectForm" (ngSubmit)="onSubmitCustomerNumber()">
  <section class="td-bg-light-gray-secondary">
    <div class="td-row center-block">
      <div class="td-col-xs-12 td-col-sm-6">
        <td-input-text
          [controlName]="customerNumberControlName"
          [baseKey]="baseKey"
          [formGroupControl]="prospectForm"
        >
        </td-input-text>
      </div>
    </div>
    <div class="td-row center-block">
      <div class="td-col-xs-12 td-col-sm-4">
        <div class="button">
          <button
            class="
              td-button td-button-block td-button-secondary
              continue-button
            "
            id="continueBtn"
            type="submit"
          >
            {{
              "agent-flow.prospect-complete-container.submitBtn-label"
                | translate
            }}
          </button>
        </div>
      </div>
    </div>
  </section>
</form>

<td-error-alert [customerNumberError]="inputRequiredFlag"></td-error-alert>

<app-generic-error [showAlert]="errorFlag" [title]="title"></app-generic-error>





import { Component, OnInit } from '@angular/core';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
@Component({
  selector: 'app-prospect-confirm',
  templateUrl: './prospect-confirm.component.html',
  styleUrls: ['./prospect-confirm.component.scss']
})
export class ProspectConfirmComponent implements OnInit {

  constructor(private prospectInputService: ProspectInputService) { }

  ngOnInit(): void {
    this.prospectInputService.invalidateClientCache().subscribe(
      () => {
        this.cleanSession();
      },
      () => {
        console.log('ClientCache failed to clear');
      });
  }

  close() {
    window.close();
  }

  cleanSession() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

<div class="row">
    <div class="td-col-xs-12 td-col-sm-10 td-col-sm-offset-1 td-col-md-8 td-col-md-offset-2">
        <h1 class="text-center">{{ "agent-flow.time-out-modal.primary-header" | translate }}</h1>
        <mat-dialog-content>
            <div class="td-row text-center">
                <div>{{ "agent-flow.time-out-modal.information-body-copy" | translate }}</div>
            </div>
            <div class="td-row text-center">
                <div style="display:inline-block;">
                    <div class="td-col-xs-12 td-col-sm-6">
                        <img src="assets/img/hours.svg" alt="Timer" />
                    </div>
                </div>
                <div style="display:inline-block;">
                    <div class="td-col-xs-12 td-col-sm-6">
                        <h3>{{counter | formatTime}}</h3>
                    </div>
                </div>
            </div>
            <div class="td-row text-center">
                <div>{{ "agent-flow.time-out-modal.resume-body-copy" | translate }}</div>
            </div>
        </mat-dialog-content>
        <div class="td-row text-center">
            <div style="display:inline-block;">
                <div class="btn-container">
                    <button class="td-button cancel-button td-button-clear-green" (click)="exit()">
                        {{ "agent-flow.time-out-modal.cancelBtn-label" | translate }}
                    </button>
                </div>
            </div>
            <div style="display:inline-block;">
                <div class="btn-container">
                    <button class="td-button td-button-block td-button-secondary continue-button" (click)="resume()">
                        {{ "agent-flow.time-out-modal.resumeBtn-label" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

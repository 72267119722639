import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
import { AgentSessionService } from 'src/app/services/agent-session-service.service';
import { Router } from '@angular/router';
import { ErrorAlertService } from 'src/app/common-ui/error-alert/error-alert.service';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { TimeoutService } from 'src/app/services/timeout.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CancelModalComponent } from 'src/app/common-ui/cancel-modal/cancel-modal.component';
import { SubmitConfirmModalComponent } from 'src/app/common-ui/submit-confirm-modal/submit-confirm-modal.component';
import { ValidatorService } from 'src/app/services/validator.service';
import { ModalService } from 'src/app/services/modal.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-prospect-input-container',
  templateUrl: './prospect-input-container.component.html',
  styleUrls: ['./prospect-input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectInputContainerComponent implements OnInit {

  country: string = "Canada";
  prospectForm: FormGroup;
  baseKey: string = "agent-flow.prospect-input-container";

  givenNameControlName: string = 'givenName';
  middleNameControlName: string = 'middleName';
  familyNameControlName: string = 'familyName';
  birthDtControlName: string = 'birthDt';
  telephoneNumControlName: string = 'telephoneNum';
  secondaryTelephoneNumControlName: string = 'secondaryTelephoneNum';
  emailAddressControlName: string = 'emailAddress';
  confirmEmailAddressControlName: string = 'confirmEmailAddress';
  streetNumControlName: string = 'streetNum';
  streetNameControlName: string = 'streetName';
  unitNumControlName: string = 'unitNum';
  unitTypeCdControlName: string = 'unitTypeCd';
  cityNameControlName: string = 'cityName';
  territoryCdControlName: string = 'territoryCd';
  postalCodeNumControlName: string = 'postalCodeNum';
  @ViewChild('btn', { static: true }) button: ElementRef;

  constructor(
    private prospectInputService: ProspectInputService,
    private agentSessionService: AgentSessionService,
    private router: Router,
    private errorAlertService: ErrorAlertService,
    private timeoutService: TimeoutService,
    private dialog: MatDialog,
    private validatorService: ValidatorService,
    private modalService: ModalService,
    private translate: TranslatePipe,
    ) {}

  ngOnInit(): void {
    this.buildForm();

    this.timeoutService.registerEvent(this.button);
    this.timeoutService.start();
  }

  buildForm(){
    const NAME_REGEX = /^(?!.*[A-Za-z]-[-])(?!.*[A-Za-z]'['])[A-Za-z\s'-]*$/;
    const PHONE_REGEX = /^\d{3}\-\d{3}\-\d{4}$/;
    const EMAIL_REGEX = /^[A-Za-z0-9_.\-]*@[A-Za-z0-9_\-]*\.[A-Za-z\.]{2,5}$/;
    const CITY_REGEX = /^[A-Za-z\s]*$/;
    const STREET_REGEX = /^(?!.*[A-Za-z]-[-])(?!.*[A-Za-z]'['])[0-9A-Za-z\s'-]*$/;
    const STREET_NUMBER = /^[0-9]*$/;
    const UNIT_NUMBER = /^[A-Za-z0-9]*$/;
    const POSTAL_CODE = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]\d[ABCEGHJ-NPRSTV-Z]\d$/;
    const DATE_REGEX = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;

    const givenNameControlName = new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.pattern(NAME_REGEX)]);
    const middleNameControlName = new FormControl(null, [Validators.maxLength(18), Validators.pattern(NAME_REGEX)]);
    const familyNameControlName = new FormControl(null, [Validators.required, Validators.maxLength(19), Validators.pattern(NAME_REGEX)]);
    const dtValidator = this.validatorService.dateValidator(true);
    const birthDtControlName = new FormControl(null, [Validators.required, Validators.pattern(DATE_REGEX), dtValidator]);
    const telephoneNumControlName = new FormControl(null, [Validators.required, Validators.maxLength(12), Validators.pattern(PHONE_REGEX)]);
    const secondaryTelephoneNumControlName = new FormControl(null, [Validators.maxLength(12), Validators.pattern(PHONE_REGEX)]);
    const emailAddressControlName = new FormControl(null, [Validators.required, Validators.maxLength(52), Validators.pattern(EMAIL_REGEX)]);
    const confirmEmailAddressControlName = new FormControl(null, [Validators.required]);
    const emailAddressMatchValidator = this.validatorService.exactMatchValidator(emailAddressControlName, confirmEmailAddressControlName);
    emailAddressControlName.setValidators([Validators.required, Validators.maxLength(52), Validators.pattern(EMAIL_REGEX),emailAddressMatchValidator]);
    confirmEmailAddressControlName.setValidators([Validators.required,emailAddressMatchValidator]);
    const streetNumControlName = new FormControl(null, [Validators.required, Validators.maxLength(7), Validators.pattern(STREET_NUMBER)]);
    const unitNumControlName = new FormControl(null, [Validators.maxLength(6), Validators.pattern(UNIT_NUMBER)]);
    const unitTypeCdControlName = new FormControl(null, []);
    const streetNameControlName = new FormControl(null, [Validators.required, Validators.maxLength(40), Validators.pattern(STREET_REGEX)]);
    const cityNameControlName = new FormControl(null, [Validators.required, Validators.maxLength(40), Validators.pattern(CITY_REGEX)]);
    const territoryCdControlName = new FormControl(null, [Validators.required]);
    const postalCodeNumControlName = new FormControl(null, [Validators.required]);
    const postalCodeValidator = this.validatorService.postalCodeValidator(territoryCdControlName, postalCodeNumControlName);
    territoryCdControlName.setValidators([Validators.required, postalCodeValidator]);
    this.prospectForm = new FormGroup({
      [this.givenNameControlName]: givenNameControlName,
      [this.middleNameControlName]: middleNameControlName,
      [this.familyNameControlName]: familyNameControlName,
      [this.birthDtControlName]: birthDtControlName,
      [this.telephoneNumControlName]: telephoneNumControlName,
      [this.secondaryTelephoneNumControlName]: secondaryTelephoneNumControlName,
      [this.emailAddressControlName]: emailAddressControlName,
      [this.confirmEmailAddressControlName]: confirmEmailAddressControlName,
      [this.streetNumControlName]: streetNumControlName,
      [this.streetNameControlName]: streetNameControlName,
      [this.unitNumControlName]: unitNumControlName,
      [this.unitTypeCdControlName]: unitTypeCdControlName,
      [this.cityNameControlName]: cityNameControlName,
      [this.territoryCdControlName]: territoryCdControlName,
      [this.postalCodeNumControlName]: postalCodeNumControlName
    });
  }

  onUnitNumChange(event: any){
    const unitType = this.prospectForm.get('unitTypeCd');
    if(event.target.value !== null){
      unitType.clearValidators();
      unitType.setValidators([Validators.required]);
      unitType.markAsTouched();
      unitType.updateValueAndValidity();
    }
    if(event.target.value === ''){
      unitType.clearValidators();
      unitType.markAsUntouched();
      unitType.updateValueAndValidity();
    }
  }

  onUnitTypeChange(event: any){
    const unitNum = this.prospectForm.get('unitNum');
    const UNIT_NUMBER = /^[A-Za-z0-9]*$/;
    if(event.target.value !== null){
      unitNum.clearValidators();
      unitNum.setValidators([Validators.maxLength(6), Validators.pattern(UNIT_NUMBER), Validators.required]);
      unitNum.markAsTouched();
      unitNum.updateValueAndValidity();
    }
    if(event.target.value === ''){
      unitNum.clearValidators();
      unitNum.setValidators([Validators.maxLength(6), Validators.pattern(UNIT_NUMBER)]);
      unitNum.markAsUntouched();
      unitNum.updateValueAndValidity();
    }
  }

  onSubmitProspect() {
    this.agentSessionService.prospectInput = this.prospectForm.value;
    this.prospectInputService.submitProspectData(this.prospectForm.value)
    .subscribe(
      responseData => {
        this.router.navigateByUrl('/waiting');
    });
  }

  openSubmitModal() {
    if (this.errorAlertService.errorsCount > 0) {
      this.errorAlertService.submit();
      this.prospectForm.markAllAsTouched();
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.ariaLabelledBy="submit-modal-label";
    dialogConfig.ariaDescribedBy="submit-modal-info";
    const dialogRef = this.dialog.open(SubmitConfirmModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == "RESUME") {
        } else if (data == "SUBMIT") {
          this.onSubmitProspect();
        }
      }
    );
  }

  openCancelModal(){
    var optionsData = {
      title: this.translate.transform("agent-flow.cancel-confirmation-modal.primary-header"),
      body: [{ content: this.translate.transform("agent-flow.cancel-confirmation-modal.information-body-copy") }],
      buttons: [{ label: this.translate.transform("agent-flow.cancel-confirmation-modal.resumeBtn-label"), id: 'resumeBtn', className: 'td-button cancel-button td-button-clear-green' },
      { label: this.translate.transform("agent-flow.cancel-confirmation-modal.cancelBtn-label"), id: 'cancelBtn', className: 'td-button td-button-block td-button-secondary continue-button' }]
    };

    var options = { data: optionsData, disableClose: true, autoFocus: true, role: "dialog", ariaDescribedBy: "mat-dialog-info-0", ariaLabelledBy: "mat-dialog-title-0", id:"mat-dialog-0"}
    this.modalService.openModal(CancelModalComponent, options);
  }
}

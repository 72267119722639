import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ErrorAlertService } from '../error-alert/error-alert.service';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-unsuccessful-match-instructions',
  templateUrl: './unsuccessful-match-instructions.component.html',
  styleUrls: ['./unsuccessful-match-instructions.component.scss']
})
export class UnsuccessfulMatchInstructionsComponent implements OnInit {
  @Input() type = 'danger';
  role;

  @ViewChild('wrapper') wrapper: ElementRef;

  constructor(public errorAlertService: ErrorAlertService, private focusMonitor: FocusMonitor) { }

  ngOnInit(): void {
    this.role = this.type === 'danger' ? 'alert' : null;
    this.errorAlertService.submitted.subscribe(submitted => {
      setTimeout(() => {
          const wrapper = this.wrapper?.nativeElement;
          if (wrapper) {
              wrapper.removeAttribute('role');
              wrapper.setAttribute('role', this.role);
              this.focusMonitor.focusVia(wrapper, 'program');
          }
      }, 100);
  });
  }

}

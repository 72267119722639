import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalBaseComponent } from 'src/app/common-ui/modal/base/base.component';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
import { DisclosureService } from 'src/app/services/disclosure.service';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent extends ModalBaseComponent implements OnInit {
  @Input() hasClose = false;
  @Input() hasTitle = true;
  @Input() name;
  @Input() classes = '';
  @Input() baseKey;
  @Input() title = 'title';
  @Input() body;
  @Input() buttons = [];
  @Output() btnClick = new EventEmitter();
  message: string;

  constructor(public matDialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private disclosureService: DisclosureService, private prospectInputService: ProspectInputService) {
    super(matDialogRef, data);
  }

  ngOnInit() {
    if (this.data) {
      this.hasClose = this.data.hasClose || this.hasClose;
      this.baseKey = this.data.baseKey || this.baseKey;
      this.title = this.data.title || this.title;
      this.body = this.data.body || this.body;
      this.buttons = this.data.buttons || this.buttons;
      this.name = this.data.name || this.name;
      this.classes = this.data.classes || this.classes;
    }
    this.classes = this.classes || (this.name ? this.name + '-modal' : '');
    this.contents = this.body ? [].concat([], this.body) : [];
    this.message = this.data.body[0].content;
  }


  onButtonClick(btn): void {
    this.btnClick.emit(btn.id);

    if (btn.id == 'cancelBtn') {
      this.prospectInputService.invalidateSessionCache().subscribe(
        () => {
          this.cleanSessionAndCloseWindow();
        }, 
        () => {
          this.cleanSessionAndCloseWindow();
        });
    } else if (btn.id == 'resumeBtn') {
      this.dialogRef.close();
    }
  }

  cleanSessionAndCloseWindow() {
    localStorage.clear();
    sessionStorage.clear();
    window.close();
  }

  @HostBinding('class') get className() {
    return this.classes;
  }

}

import { Observable, throwError, timer } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";

export const GenericRetryStrategy = ({
  maxRetryAttempts = 30,
  delayDuration = 10000,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number,
  delayDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    switchMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      return timer(delayDuration);
    })
  );
};

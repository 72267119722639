import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalBaseComponent } from 'src/app/common-ui/modal/base/base.component';
import { DisclosureService } from 'src/app/services/disclosure.service';

export const btnCancelAppId = 'cancelApp';

@Component({
  selector: 'app-privacy-disclosure',
  templateUrl: './privacy-disclosure.component.html',
  styleUrls: ['./privacy-disclosure.component.scss']
})
export class PrivacyDisclosureComponent extends ModalBaseComponent implements OnInit {
  @Input() hasClose = true;
  @Input() hasTitle = false;
  @Input() name;
  @Input() classes = '';
  @Input() baseKey;
  @Input() title = 'title';
  @Input() body;
  @Input() buttons = [];
  @Output() btnClick = new EventEmitter();
  message: string;
  constructor(public matDialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private disclosureService: DisclosureService) {
    super(matDialogRef, data);
  }

  ngOnInit() {
    if (this.data) {
      this.hasClose = this.data.hasClose || this.hasClose;
      this.baseKey = this.data.baseKey || this.baseKey;
      this.title = this.data.title || this.title;
      this.body = this.data.body || this.body;
      this.buttons = this.data.buttons || this.buttons;
      this.name = this.data.name || this.name;
      this.classes = this.data.classes || this.classes;
    }
    this.classes = this.classes || (this.name ? this.name + '-modal' : '');
    this.contents = this.body ? [].concat([], this.body) : [];
    this.message = this.data.body[0].content;
  }


  onButtonClick(btn): void {
    this.btnClick.emit(btn.id);

    if (btn.id == 'printBtn') {
      this.disclosureService.print(this.message, 'Privacy Agreement', true);
    } else if (btn.id == 'saveBtn') {
      this.disclosureService.download(this.message, 'Privacy Agreement', true);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


}

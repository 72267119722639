import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CommonUiModule } from './common-ui/common-ui.module';
import { AgentFlowModule } from './agent-flow/agent-flow.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleConfig, UserIdleModule, UserIdleService } from 'angular-user-idle';
import { ConfigurationService } from './services/api/configuration.service';
import { CustomerFlowModule } from './customer-flow/customer-flow.module';
import { ModalService } from './services/modal.service';
import { ModalConfigModule } from './common-ui/modal/modal.module';

const config: UserIdleConfig = {
  timeout: 0,
  idle: 0
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonUiModule,
    AgentFlowModule,
    CustomerFlowModule,
    HttpClientModule,
    ModalConfigModule.withConfig([]),
    TranslateModule.forRoot({
      defaultLanguage: 'en-CA',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    UserIdleModule.forRoot(config)
  ],
  providers: [UserIdleService,
    ModalService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      multi: true,
      deps: [HttpClient, ConfigurationService, TranslateService]
    },
    /* { provide: STORE_OPTIONS, useValue: {} } */],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function loadConfiguration(http: HttpClient, configService: ConfigurationService, translate: TranslateService) {
  const lang = getURL(new URL(window.location.href)).searchParams.get('lang') || 'en-CA';
  return () => configService.loadConfig().then(timeoutConfig => {
    config.timeout = timeoutConfig.timeout;
    config.idle = timeoutConfig.idle - timeoutConfig.timeout;
    console.log("Timeout (in secs): " + config.timeout);
    console.log("Idle Time (in secs): " + config.idle);
    translate.setDefaultLang('en-CA');
    //translate.use(timeoutConfig.lang);
    translate.use(lang);
  }
  )
}

function getURL(url) {
  let fixedUrl = new URL(url);
  let search = url.search;
  let hash = url.hash;
  const position = url.hash.indexOf('?');
  if (search.length <= 1 && position >= 0) {
    search = hash.substr(position);
    hash = hash.substr(0, position);
    fixedUrl.hash = hash;
    fixedUrl.search = search;
    fixedUrl.href = fixedUrl.toString();
  }
  return fixedUrl;
}

<div class="row">
    <div class="
        td-col-xs-12
        td-col-sm-10
        td-col-sm-offset-1
        td-col-md-8
        td-col-md-offset-2
      ">
        <h1 class="text-center ng-star-inserted">{{ "customer-flow.employee-begin-session-container.primary-header" |
            translate }}</h1>
    </div>
</div>
<div class="td-row">
    <div class="td-col-xs-12 td-col-sm-10 td-col-sm-offset-1 td-col-md-8 td-col-md-offset-2">
        <p class="text-center">{{ "customer-flow.employee-begin-session-container.information-body-copy" | translate }}
        </p>
    </div>
</div>
<div class="row">
    <div class="
        td-col-xs-12
        td-col-sm-10
        td-col-sm-offset-1
        td-col-md-8
        td-col-md-offset-2
      ">
        <h2 class="text-center ng-star-inserted">{{ "customer-flow.employee-begin-session-container.secondary-header" |
            translate }}</h2>
    </div>
</div>
<div class="td-row">
    <div class="td-col-xs-12 td-col-sm-10 td-col-sm-offset-1 td-col-md-8 td-col-md-offset-2">
        <ol class="td-list">
            <li>{{ "customer-flow.employee-begin-session-container.instruction-body-copy_1" | translate }}</li>
            <li>{{ "customer-flow.employee-begin-session-container.instruction-body-copy_2" | translate }}</li>
            <li>{{ "customer-flow.employee-begin-session-container.instruction-body-copy_3" | translate }}</li>
            <li>{{ "customer-flow.employee-begin-session-container.instruction-body-copy_4" | translate }}</li>
            <li>{{ "customer-flow.employee-begin-session-container.instruction-body-copy_5" | translate }}</li>
        </ol>
    </div>
</div>
<div class="td-row">
    <div class="td-col-xs-12 td-col-sm-10 td-col-sm-offset-1 td-col-md-8 td-col-md-offset-2  td-bg-light-gray">
        <p class="text-center">{{ "customer-flow.employee-begin-session-container.legal-consent-body-copy_0" | translate
            }}
            <a #btn class="td-link-inline" href="javascript:void(0)" (click)="openDisclosureDialog();">{{
                "customer-flow.employee-begin-session-container.legal-consent-body-copy_1" |
                translate}}</a>
            {{ "customer-flow.employee-begin-session-container.legal-consent-body-copy_2" | translate }}
        </p>
        <p class="text-center">{{ "customer-flow.employee-begin-session-container.warning-message-body-copy" | translate
            }}
        </p>
    </div>
</div>
<div class="td-row">
    <div class="td-col-xs-12 td-col-sm-offset-4 td-col-sm-4">
        <div class="btn-container">
            <a role="button" class="vme-verified-button" (click)="openVerifiedMe()" tabindex="0">
                <img alt="{{ 'customer-flow.employee-begin-session-container.continueBtn-label' | translate }}" src="{{'customer-flow.vme-button.path' | translate }}" class="vme-icon" />
            </a>
          </div>
        <div class="btn-container cancel-btn-container">
            <a class="td-link-inline cancel-button" href="javascript:void(0)" id="btnCancel"
                (click)="openCancelConfirmation()" allowunload="true">{{
                "customer-flow.employee-begin-session-container.cancelBtn-label" | translate
                }}</a>
        </div>
    </div>
</div>
import { ENTER, LEFT_ARROW, RIGHT_ARROW, SPACE } from '@angular/cdk/keycodes';
import {
  Optional,
  InjectionToken,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  MatCalendar,
  MatCalendarCell,
  MatDatepickerContent,
} from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MAX_MOBILE_WIDTH = 767;

export enum _POSITION {
  FIRST,
  LAST,
}

export const TD_DATEPICKER_HEADER_LABELS = new InjectionToken<any>(
  'TD_DATEPICKER_HEADER_LABELS'
);
export const defaultLabels = {
  header: 'common-ui.datepicker.pickyear',
  closeBtn: 'common-ui.datepicker.close-aria',
  nextBtn: 'common-ui.datepicker.next-aria',
  prevBtn: 'common-ui.datepicker.prev-aria',
};

@Component({
  selector: 'td-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent<D>
  implements OnInit, AfterViewChecked, OnDestroy
{
  private destroyed = new Subject<void>();

  _decade: number;
  _yearSelected = false;
  _monthSelected = false;
  _innerWidth: number;

  yearRows: MatCalendarCell[][] = [];

  WEEKDAYS = 7;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._innerWidth = window.innerWidth;
    this.reloadYears();
  }

  constructor(
    private calendar: MatCalendar<D>,
    private cdr: ChangeDetectorRef,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private pickerContent: MatDatepickerContent<D>,
    _translate: TranslateService,
    @Optional() @Inject(TD_DATEPICKER_HEADER_LABELS) public labels
  ) {
    this.setupSubscriptions();
    this.checkLocale(_translate.currentLang);
  }

  ngOnInit(): void {
    const year = this.calendar.activeDate
      ? parseInt(this.dateAdapter.format(this.calendar.activeDate, 'YYYY'))
      : new Date().getFullYear();
    this.decade = Math.floor(year / 10);
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this._refreshDate();
    }, 100);
  }

  setupSubscriptions() {
    this.calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.cdr.markForCheck();
      this._refreshDate();
    });
    this.calendar.yearSelected
      .pipe(takeUntil(this.destroyed))
      .subscribe((e) => {
        this.cdr.markForCheck();
        this._yearSelected = true;
      });
    this.calendar.monthSelected
      .pipe(takeUntil(this.destroyed))
      .subscribe((e) => {
        this.cdr.markForCheck();
        this._monthSelected = true;
        setTimeout(() => {
          this.calendar.monthView._weekdays.forEach((wkday) => {
            wkday.narrow = wkday.long.substr(0, 3).toUpperCase();
          });
          this.calendar.monthView.ngAfterContentInit();
          this._refreshDate();
        }, 100);
      });
    this.calendar._userSelection
      .pipe(takeUntil(this.destroyed))
      .subscribe((e) => {
        this.cdr.markForCheck();
        this._refreshDate();
      });
  }

  checkLocale(locale) {
    if (locale && locale.substring(0, 1).toLocaleLowerCase() === 'f') {
      this.dateAdapter.setLocale('fr');
    }
  }

  isCurrentYear() {
    return this.isYearSelected(
      this.dateAdapter.getYear(this.dateAdapter.today())
    );
  }

  private _refreshDate(): void {
    if (
      this.calendar &&
      this.calendar.monthView &&
      this.calendar.monthView._weeks
    ) {
      this.calendar.monthView._weeks.forEach((currentWeek, index) => {
        if (currentWeek.length < this.WEEKDAYS) {
          if (index === 0) {
            // this.withMonthDates(
            //   this.WEEKDAYS - currentWeek.length,
            //   _POSITION.FIRST
            // );
          } else {
            // this.withMonthDates(
            //   this.WEEKDAYS - currentWeek.length,
            //   _POSITION.LAST
            // );
          }
        }
      });
    }
  }

  withMonthDates(missingDays: number = 0, _position: _POSITION) {
    const rowEle = this._findCaldendarRowInMonthView(_position);
    if (
      rowEle &&
      this.notCommentChild(rowEle.childNodes) < this.WEEKDAYS &&
      missingDays > 0
    ) {
      this.cleanRowInMonthViewByIndex(
        rowEle,
        _position === _POSITION.FIRST ? 0 : rowEle.childNodes.length - 1
      );

      let month = this.dateAdapter.getMonth(this.calendar.activeDate);
      let year = this.dateAdapter.getYear(this.calendar.activeDate);
      if (month === 11) {
        month = -1;
        year = year + 1;
      }
      const firstday = this.dateAdapter.createDate(year, month + 1, 1);
      const days = this.dateAdapter.getDate(this.calendar.activeDate);
      const lastday = this.dateAdapter.addCalendarDays(
        this.calendar.activeDate,
        -days
      );
      for (let i = 0; i < missingDays; i++) {
        if (_position === _POSITION.LAST) {
          rowEle.appendChild(this._getMissingDateCellElement(firstday, i));
        } else {
          rowEle.insertBefore(
            this._getMissingDateCellElement(lastday, -i),
            rowEle.childNodes[0]
          );
        }
      }
    }
  }

  private _toRemove(node: Element) {
    return (
      node &&
      (this.isCommentNode(node.nodeType) ||
        node.getAttribute('aria-hidden') === 'true' ||
        (node.getAttribute('colspan') &&
          node.getAttribute('colspan').length > 0))
    );
  }

  private cleanRowInMonthViewByIndex(rowEle: Element, index: number) {
    if (rowEle) {
      let node = rowEle.childNodes[index];
      while (this._toRemove(node as Element)) {
        rowEle.removeChild(node);
        node = rowEle.childNodes[index];
      }
    }
  }

  notCommentChild(row) {
    return _.filter(
      row,
      (item) => !this.isCommentNode(item.nodeType) && item.innerHTML !== ''
    ).length;
  }

  public _findCaldendarRowInMonthView(_position) {
    const tbodyEle = document.querySelector('mat-month-view table tbody');
    let found = null;
    if (tbodyEle && tbodyEle.childNodes) {
      _.every(tbodyEle.childNodes, (node, index) => {
        if (
          _position === _POSITION.LAST &&
          !this.isCommentNode(
            tbodyEle.childNodes[tbodyEle.childNodes.length - index]
          ) &&
          !found
        ) {
          found = tbodyEle.childNodes[tbodyEle.childNodes.length - index];
        } else if (
          _position === _POSITION.FIRST &&
          !this.isCommentNode(node) &&
          !found &&
          (node as Element).getAttribute &&
          (node as Element).getAttribute('aria-hidden') !== 'true'
        ) {
          found = node;
        }
        return !found;
      });
    }
    return found;
  }

  private _getMissingDateCellElement(dayRef: D, offset: number): Element {
    const cell: MatCalendarCell = this._getMissingDateCell(dayRef, offset);
    const newel = document.createElement('td');
    newel.setAttribute('aria-hidden', 'true');
    newel.setAttribute('aria-label', cell.ariaLabel);
    newel.setAttribute(
      'class',
      'ctHidden mat-calendar-body-disabled date-grayed'
    );
    newel.innerHTML = cell.displayValue;
    return newel;
  }

  private _getMissingDateCell(dayRef: D, offset: number): MatCalendarCell {
    const date = this.dateAdapter.addCalendarDays(dayRef, offset);
    const day = this.dateAdapter.getDate(date);
    const ariaLabel = this.dateAdapter.format(date, 'MMM DD, YYYY');
    return new MatCalendarCell(day, day.toString(), ariaLabel, false);
  }

  private _isMobile() {
    return this._innerWidth <= MAX_MOBILE_WIDTH;
  }

  get numYrPerRow() {
    return this._isMobile() ? 4 : 5;
  }

  getYearElement(yr: number) {
    return document.querySelector('#' + 'year' + yr);
  }

  movetoYear(yr: number) {
    const ele = this.getYearElement(yr);
    if (ele) {
      (ele as HTMLElement).focus();
    }
  }

  isYearOutOfRange(yr: number) {
    if (this.calendar.minDate) {
      const minYr = this.dateAdapter.getYear(this.calendar.minDate);
      if (yr < minYr) {
        return true;
      }
    }
    if (this.calendar.maxDate) {
      const maxYr = this.dateAdapter.getYear(this.calendar.maxDate);
      if (yr > maxYr) {
        return true;
      }
    }
    return false;
  }

  reloadYears(val?: number) {
    const numYears = 10;
    const yearStart = this.decade * 10;
    let newRows: MatCalendarCell[][];
    let rowIndex = 0;
    newRows = [];
    for (let i = 0; i < numYears; ) {
      newRows[rowIndex] = [];
      for (let j = 0; j < this.numYrPerRow && i < numYears; j++) {
        newRows[rowIndex][j] = this.makeCell(yearStart + i);
        i++;
      }
      rowIndex++;
    }
    this.yearRows = newRows;
    this._focusActiveCell();
  }

  _focusActiveCell() {
    const activeCell: HTMLElement | null = document.querySelector(
      '#multiyear-picker .mat-calendar-body-active'
    );
    if (activeCell) {
      activeCell.focus();
    }
  }

  makeCell(yr: number): MatCalendarCell {
    return {
      value: yr,
      displayValue: yr.toString(),
      ariaLabel: yr.toString(),
      enabled: true,
      cssClasses: 'ctHidden',
      compareValue: 1,
    };
  }

  get toSelectYear() {
    return !this._yearSelected && !this._monthSelected;
  }
  get toSelectMonth() {
    return this._yearSelected && !this._monthSelected;
  }
  get toSelectDate() {
    return this._yearSelected && this._monthSelected;
  }

  get periodLabel() {
    return this._decade * 10;
  }
  get yearLabel() {
    return this.dateAdapter.format(this.calendar.activeDate, 'YYYY');
  }
  get monthYearLabel() {
    return this.dateAdapter.format(
      this.calendar.activeDate,
      this.dateFormats.display.monthYearLabel
    );
  }

  get decade() {
    return this._decade;
  }
  set decade(val: number) {
    this._decade = val;
    this.reloadYears(val);
  }

  gotoDecades() {
    this.calendar.currentView = 'multi-year';
    this._yearSelected = false;
  }

  gotoYears() {
    this.calendar.currentView = 'year';
    this._yearSelected = true;
    this._monthSelected = false;
  }

  getDecadeMinYear() {
    return this.dateAdapter.createDate(this.decade * 10, 0, 1);
  }
  getDecadeMaxYear() {
    return this.dateAdapter.createDate((this.decade + 9) * 10, 0, 1);
  }

  getDecade(value) {
    this.decade += value;
    this.calendar.activeDate = this.getDecadeMinYear();
    this.calendar.startAt = this.calendar.activeDate;
    this.reloadYears();
  }

  isYearSelected(yr: number) {
    return (
      this.calendar.activeDate &&
      this.dateAdapter.getYear(this.calendar.activeDate) === yr
    );
  }

  onYearSelected(yr: number) {
    if (this.isYearOutOfRange(yr)) {
      return;
    }
    this.calendar.activeDate = this.calendar.activeDate
      ? this.dateAdapter.createDate(
          yr,
          this.dateAdapter.getMonth(this.calendar.activeDate),
          this.dateAdapter.getDate(this.calendar.activeDate)
        )
      : this.dateAdapter.createDate(yr, 0, 1);
    this.gotoYears();
  }

  previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

  dismiss() {
    this.pickerContent.datepicker.close();
  }

  _handleCalendarBodyKeydown(event: KeyboardEvent): void {
    switch (event.keyCode) {
      case LEFT_ARROW:
        this.changeYearRange(
          -1,
          this.dateAdapter.getYear(this.calendar.activeDate) < this.decade * 10
        );
        break;
      case RIGHT_ARROW:
        this.changeYearRange(
          1,
          this.dateAdapter.getYear(this.calendar.activeDate) >
            (this.decade + 1) * 10
        );
        break;
      case ENTER:
      case SPACE:
        this.gotoYears();
        break;
    }
  }

  changeYearRange(value, condition) {
    if (
      !this.isYearOutOfRange(
        this.dateAdapter.getYear(this.calendar.activeDate) + value
      )
    ) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        value
      );
      if (condition) {
        this.decade += value;
        this.calendar.startAt = this.calendar.activeDate;
        this.reloadYears();
      }
    }
  }

  isCommentNode(type) {
    return type == Node.COMMENT_NODE;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

<div class="td-row modal-style">
  <div class="td-col-xs-12 td-col-sm-10 td-col-sm-offset-1 td-col-md-8 td-col-md-offset-2 flex-container">
      <h1 class="text-center" id="submit-modal-label">{{ "agent-flow.submit-confirmation-modal.primary-header" | translate }}</h1>
      <mat-dialog-content id="submit-modal-info">
        <div>
          <div class="td-row text-center">
              <div tabindex="0">{{ "agent-flow.submit-confirmation-modal.information-body-copy1" | translate }}
              <br/>
                {{ "agent-flow.submit-confirmation-modal.information-body-copy2" | translate }}</div>
        </div>
      </div>
      </mat-dialog-content>
  </div>
  <td-modal-buttons [buttons]="buttons" (btnClick)="onButtonClick($event)"></td-modal-buttons>
</div>



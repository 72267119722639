import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as base64 from 'base-64';
import * as utf8 from 'utf8';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class DisclosureService {
    static TD_STANDARD_REF_CODE_MAX_NUM_CHAR = 120;

    constructor(private _domSanitizer: DomSanitizer) { }

    private _removeTdStandardRef(htmlCode: string): string {
        if (htmlCode) {
            const index = htmlCode.indexOf('www.tdstandards.com');
            if (index > -1) {
                const len = htmlCode.length;
                let begin = -1;
                let end = -1;
                for (let i = index, found = false; i > 0 && !found; i--) {
                    if (htmlCode.charAt(i) === '<') {
                        begin = i;
                        found = true;
                    }
                }
                for (let i = index, found = false; i < len && !found; i++) {
                    if (htmlCode.charAt(i) === '>') {
                        end = i;
                        found = true;
                    }
                }
                if (begin !== -1 && end !== -1 && end - begin < DisclosureService.TD_STANDARD_REF_CODE_MAX_NUM_CHAR) {
                    const pre = htmlCode.substring(0, begin);
                    const post = htmlCode.substring(end + 1);
                    return pre + post;
                }
            }
            htmlCode = htmlCode.replace(/.body_(.+) a, a:link, a:visited, a:hover/, '.body_$1 a, .body_$1 a:link, .body_$1 a:visited, .body_$1 a:hover');
        }
        return htmlCode;
    }

    decodeContentAsTrustedHtml(content: string | string[], original = false) {
        const contentUtf8 = content instanceof Array ?
            content.map(c => (c ? this.base64ToUtf8(c) : '')).join('') :
            content ? this.base64ToUtf8(content) : '';
        if (original) {
            return contentUtf8;
        }
        return this._domSanitizer.bypassSecurityTrustHtml(this._removeTdStandardRef(contentUtf8));
    }

    base64ToUtf8(text) {
        return utf8.decode(base64.decode(text));
    }

    print(content, title, isSafe = false) {
        const safeContent = isSafe ? content : this.decodeContentAsTrustedHtml(content, true);
        const printWindow = window.open('', '', 'width=500,height=500');
        printWindow.document.write(safeContent);
        let titleElement = printWindow.document.getElementsByTagName('title').item(0);
        if (titleElement) {
            titleElement.innerHTML = title;
        } else {
            titleElement = printWindow.document.createElement('title');
            titleElement.innerHTML = title;
            printWindow.document.head.appendChild(titleElement);
        }
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    }

    download(content, filename, isSafe = false) {
        const safeContent = isSafe ? content : this.decodeContentAsTrustedHtml(content, true);
        const blob = new Blob([safeContent.toString()], { type: 'text/html;charset=utf-8;' });
        try {
            if (window.navigator && window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, filename + '.html');
                return;
            }
            const isSafari = this._isSafari();
            if (isSafari) {
                const _WindObj = window.open('', '');
                _WindObj.document.write(safeContent);
                _WindObj.document.title = '';
                _WindObj.focus();
            } else {
                const url = window.URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.setAttribute('href', url);
                downloadLink.setAttribute('download', filename + '.html');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.removeChild(downloadLink);
                window.URL.revokeObjectURL(url);
            }
        } catch (e) { }
    }

    private _isSafari() {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.includes('safari') && !ua.includes('chrome');
    }
}
<ng-template #popContent>
    <div #tooltipContent class="tooltip-box" [ngClass]="classes"
        cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div class="header-close">
            <a  #tooltipClose
                [id]="tooltipId+'-close'"
                class="td-icon icon-small td-icon-close text-right"
                (keydown.space)="dismiss($event)"
                (keydown.enter)="dismiss($event)"
                (click)="dismiss($event)"
                cdkFocusInitial
                role="button"
                tabindex="0"
                [attr.aria-label]="'common-ui.tooltip.closeIcon.accessibilityTag' | translate">
            </a>
        </div>
        <div #tooltipMessage tabindex="0" [style.outline]="0"
            [id]="tooltipId+'-aria-desc'" class="tooltip-message"
            [attr.aria-label]="(tooltipText || (labelText + '.tool-tip')) | translate">
            {{(tooltipText || (labelText + '.tool-tip')) | translate}}
        </div>
    </div>
</ng-template>

    <div class="tooltip-container" *ngIf="hasTooltip">
        <a
            href="javascript:void(0)"
            tabindex="0"
            class="td-icon td-icon-tooltip tooltip-button"
            [id]="tooltipId+'-icon'"
            type="button"
            role="button"

            [attr.aria-label]="(labelText | translate) + ' ' + ('common-ui.tooltip.helpIcon.accessibilityTag' | translate)"

            #p="ngbPopover"
            [ngbPopover]="popContent"
            [placement]="placement"
            [autoClose]="'outside'"
            [triggers]="'manual'"
            container="body"
            (shown)="onOpen()"
            (hidden)="onClose()"

            (keydown.enter)="showTooltip($event, p)"
            (keydown.space)="showTooltip($event, p)"
            (click)="showTooltip($event, p)">
        </a>
    </div>

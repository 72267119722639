<div class="td-row success-banner" role="alert">
  <p class="text-center">
    <span
      class="td-icon icon-small td-icon-approved approved-icon"
      style="font-size: 20px; vertical-align: -3px"
    ></span>
    {{
      "agent-flow.prospect-complete-container.validation-confirmation-header"
        | translate
    }}
  </p>
</div>

<div class="td-row dotted-divider"></div>

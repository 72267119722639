import { Component, OnInit,  EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-submit-confirm-modal',
  templateUrl: './submit-confirm-modal.component.html',
  styleUrls: ['./submit-confirm-modal.component.scss']
})
export class SubmitConfirmModalComponent implements OnInit {
  buttons: {} = [{ label: this.translate.transform("agent-flow.submit-confirmation-modal.resumeBtn-label"), id: 'resumeBtn', className: 'td-button cancel-button td-button-clear-green' },
  { label: this.translate.transform("agent-flow.submit-confirmation-modal.submitBtn-label"), id: 'submitBtn', className: 'td-button td-button-block td-button-secondary continue-button' }]
  @Output() btnClick = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<SubmitConfirmModalComponent>,private translate: TranslatePipe) { }

  ngOnInit(): void {
  }

  onButtonClick(btn): void {
    this.btnClick.emit(btn.id);

    if (btn.id == 'resumeBtn') {
      this.dialogRef.close("RESUME");
    } else if (btn.id == 'submitBtn') {
      this.dialogRef.close("SUBMIT");
    }
  }
}

import { Injectable } from '@angular/core';
import { AssetBundleModel } from '../models/agent-flow/api/common/asset-bundle.model';
import { ProspectPageInputModel } from '../models/agent-flow/ui/prospect-page-input.model';

@Injectable({
  providedIn: 'root'
})
export class AgentSessionService {

  private _matchingResultCd?: String;
  private _prospectInput: ProspectPageInputModel;
  private _otherFinancialInstitutionBundleData?: AssetBundleModel = {};
  private _creditBureauBundleData?: AssetBundleModel = {};

  constructor() {}

  get matchingResultCd(): String {
    return this._matchingResultCd;
  }
  set matchingResultCd(matchingResultCd: String) {
    this._matchingResultCd = matchingResultCd;
  }
  get prospectInput(): ProspectPageInputModel {
    return this._prospectInput;
  }
  set prospectInput(prospectInput: ProspectPageInputModel) {
    this._prospectInput = prospectInput;
  }
  get otherFinancialInstitutionBundleData(): AssetBundleModel {
    return this._otherFinancialInstitutionBundleData;
  }
  set otherFinancialInstitutionBundleData(otherFinancialInstitutionBundleData: AssetBundleModel) {
    this._otherFinancialInstitutionBundleData = otherFinancialInstitutionBundleData;
  }
  get creditBureauBundleData(): AssetBundleModel {
    return this._creditBureauBundleData;
  }
  set creditBureauBundleData(creditBureauBundleData: AssetBundleModel) {
    this._creditBureauBundleData = creditBureauBundleData;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'td-input-label',
    templateUrl: './input-label.component.html',
    styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent implements OnInit {
    @Input() formGroupControl: FormGroup;
    @Input() labelText: string;
    @Input() controlName: string;
    @Input() baseKey: string;
    @Input() hasTooltip = false;
    @Input() tooltipText: string;
    @Input() highlight: string;
    @Input() labelLink;
    @Input() optional;

    constructor() { }

    ngOnInit() { }

    get control() {
        return this.formGroupControl.controls[this.controlName];
    }

    get isRequired() {
        if (this.control && this.control.validator) {
            const validator = this.control.validator(new FormControl());
            return (validator && validator.required);
        }
        return false;
    }

    get isOptional() {
        if (this.optional === false) {
            return false;
        } else {
            return !this.isRequired;
        }
    }
}

<div class="input-container" [class.input-focused]="focused && _inputValid" [ngClass]="customFormFieldClass" [class.date-error]="!_inputValid">
    <span class="sr-only" id="placeholder">{{ placeholder }}</span>
    <input style="margin-top: 0px;"
        type="tel"
        [id]="id"
        [attr.aria-labelledby]="labelledby"
        class="ctHidden datepick-control input-field form-control"
        placeholder="{{'agent-flow.prospect-input-container.birthDt.placeholder' | translate}}"
        (dateChange)="_onChange($event.value)"
        (dateInput)="_onChange($event.value)"
        [(ngModel)]="_dateInput"
        [mask]="dateMask"
        (blur)="inputBlurred()"
        (focus)="triggerBlurred()"
        (mouseleave)="triggerMouseleft()"
        (mouseenter)="triggerMouseentered()"
        (keydown.enter)="openDatepicker(datepicker)"
        (keydown.space)="openDatepicker(datepicker)"
    />
    <button
        (click)="openDatepicker(datepicker)"
        tabindex="-1"
        class="calender-trigger"
        (blur)="triggerBlurred()"
        (focus)="triggerFocused()"
        (mouseleave)="triggerMouseleft()"
        (mouseenter)="triggerMouseentered()"
        aria-hidden="true"
        type="button"
        id="datepicker-trigger-button"
    >
        <span class="td-icon td-icon-colour-primary icon-small td-icon-selectADate"></span>
    </button>
</div>

<mat-form-field appearance="standard" class="hidden-input">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        matInput
        class="ctHidden hidden-input"
        (keydown.arrowdown)="openDatepicker(datepicker)"
        (dateChange)="_onChange($event.value)"
        (dateInput)="_onChange($event.value)"
        [formControl]="_dateCtl"
        [matDatepickerFilter]="_customFilter"
        [matDatepicker]="datepicker"
        [min]="_min"
        [max]="_max"
    />
    <mat-datepicker
        #datepicker
        class = "ctHidden"
        (closed)="datepickerClosed(datepicker)"
        [touchUi]="touchUi"
        (monthSelected)="onMonthSelected($event, datepicker)"
        panelClass="month-picker"
        startView="multi-year"
        [calendarHeaderComponent]="calenderHeader"
        [startAt]="defaultYear"
    >
    </mat-datepicker>
</mat-form-field>

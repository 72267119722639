import { NgModule, ModuleWithProviders, Optional, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { ModalBaseComponent } from './base/base.component';
import { ModalCloseComponent } from './close/close.component';
import { ModalButtonsComponent } from './buttons/buttons.component';
import { ModalTitleComponent } from './title/title.component';
import { ModalService, MODAL_CONFIG } from 'src/app/services/modal.service';


@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule],
  exports: [ModalBaseComponent, ModalButtonsComponent, ModalCloseComponent, ModalTitleComponent],
  declarations: [ModalBaseComponent, ModalButtonsComponent, ModalCloseComponent, ModalTitleComponent],
  entryComponents: [ModalBaseComponent],
  providers: [ModalService, { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }],
})
export class ModalConfigModule {
  static withConfig(config): ModuleWithProviders<ModalConfigModule> {
    return {
      ngModule: ModalConfigModule,
      providers: [
        ModalService,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        { provide: MODAL_CONFIG, useValue: config || [], multi: true },
      ]
    };
  }

  constructor(modalService: ModalService, @Optional() @Inject(MODAL_CONFIG) configs = []) {
    configs?.forEach((config = []) => {
      config?.forEach(m => {
        if (m?.name && m?.modal) {
          modalService.registerModal(m.name, m.modal);
        }
      });
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() label = '';
  constructor() {}

  ngOnInit(): void {}

  skipToMain($event) {
    $event.preventDefault();
    const h1 = document.getElementsByTagName('h1')[0];
    if (h1) {
        h1.focus();
    }
  }

  get rootHref() {
    return location.pathname;
  }

  goToRoot() {
    location.href = this.rootHref;
}

}

import { Component, OnInit } from '@angular/core';
import { ProspectInputService } from 'src/app/services/api/prospect-input-service.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorAlertService } from 'src/app/common-ui/error-alert/error-alert.service';


@Component({
  selector: 'cif-input',
  templateUrl: './cif-input.component.html',
  styleUrls: ['./cif-input.component.scss'],
})
export class CifInputComponent implements OnInit {
  prospectForm: FormGroup;
  baseKey: string = 'agent-flow.prospect-complete-container';
  customerNumberControlName: string = 'customerNumber';
  inputRequiredFlag = false;
  errorFlag: boolean = false;
  title: string = '';


  constructor(
    private prospectInputService: ProspectInputService,
    private router: Router,
    private errorAlertService: ErrorAlertService
  ) {}

  ngOnInit(): void {
    this.prospectForm = new FormGroup({
      [this.customerNumberControlName]: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(9),
      ]),
    });
  }

  onSubmitCustomerNumber() {
    if (this.errorAlertService.errorsCount > 0) {
      this.inputRequiredFlag = true;
      this.errorFlag = false;
      this.errorAlertService.submit();
      this.prospectForm.markAllAsTouched();
      return;
    }

    this.prospectInputService
      .captureConsentData(this.prospectForm.controls['customerNumber'].value)
      .subscribe((responseData) => {
        console.log('Response from consent events');
        console.log(responseData);
        if(responseData.body.nextStateName == "UNEXPECTED_SYSTEM_FAILURE"){
          this.errorFlag = true;
          this.title = 'agent-flow.prospect-complete-container.error-body-copy';
        }

        if(responseData.body.nextStateName == "CIF_MATCHING_FAILURE"){
          this.errorFlag = true;
          this.title = 'agent-flow.error-messages.customerNumber-mismatch';
        }

        if(responseData.body.nextStateName == "ON_BOARDING_COMPLETE"){
          this.router.navigateByUrl('/success');
        }
      },
       (responseData) => {
          this.errorFlag = true;
          this.title = 'agent-flow.prospect-complete-container.error-body-copy';
      });
  }
}
